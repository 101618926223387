// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    let minutes = 0, seconds = 0;

    /* eslint-disable-next-line no-restricted-globals */
    self.onmessage = (message) => {
        if (message && message.data && message.data.msg === 'start') {
            minutes = message.data.countMin;
            startCountdown();
        }
    }


    function  startCountdown() {
        /*console.log("Apna Kaam Banta");
        console.log("Minutes: " + minutes + " Seconds: " + seconds);*/

        seconds -= 1;

        if (seconds < 0 && minutes != 0) {
            minutes -= 1;
            seconds = 59;
        } else if (seconds < 10 && seconds.length != 2) {
            seconds = '0' + seconds;
        }
        /* eslint-disable-next-line no-restricted-globals */
        self.postMessage({countdownMinutes: minutes, countdownSeconds: seconds});

        if (minutes == 0 && seconds == 0) {
            /* eslint-disable-next-line no-restricted-globals */
            self.postMessage({logout: true});
        } else {
            setTimeout(startCountdown, 1000)
        }
    }
};




