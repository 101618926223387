import React, {Component} from 'react';
import {Redirect, withRouter} from 'react-router-dom'
import {DOMAIN} from "../api-config";

import IdleTimer from 'react-idle-timer'
import BModal from "react-bootstrap/Modal";
import WorkerBuilder from '../workers/WorkerBuilder';
import Worker from '../workers/countdownWorker';

//let countdownWorker = new Worker(worker_script);
let countdownWorker;

class idletimer extends Component {

    constructor(props, context) {
        super(props, context);
        // console.log(DOMAIN);
        this.idleTimer = null
        this.handleOnAction = this.handleOnAction.bind(this);
        this.handleOnActive = this.handleOnActive.bind(this);
        this.handleOnIdle = this.handleOnIdle.bind(this);

        this.state = {
            redirect: false,
            countdownMinutes: 2,
            countdownSeconds: 0,
            countdownFlag: false,
            showSessionAlert: false,
            // countdownWorker: ""
        };
    };


    logout = () => {
        sessionStorage.clear();
        localStorage.removeItem("emp_id");
        localStorage.removeItem("emp_name");
        localStorage.removeItem("emp_email");
        localStorage.removeItem("emp_token");
        localStorage.removeItem("emp_username");
        localStorage.removeItem("emp_image");
        countdownWorker.terminate();
        countdownWorker = undefined;
        this.setState({redirect: true});
    }

    componentDidMount() {

    }

    countdown = () => {


        if(countdownWorker === undefined) {
            countdownWorker =  new WorkerBuilder(Worker);
        }
        countdownWorker.postMessage({msg: 'start', countMin: this.state.countdownMinutes});

        let dummyThis = this;
        countdownWorker.onmessage = function (event) {
            if(event.data.logout) {
                dummyThis.logout();
            }
            dummyThis.setState({countdownMinutes: event.data.countdownMinutes});
            dummyThis.setState({countdownSeconds: event.data.countdownSeconds});
        };
    }

    resetIdleTimer = () => {
        this.setState({countdownFlag: false});
        this.setState({showSessionAlert: false});
        this.idleTimer.start();
        countdownWorker.terminate();
        countdownWorker = undefined;
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to="/login"/>;
        }
        return (<div><IdleTimer
            ref={ref => {
                this.idleTimer = ref
            }}
            timeout={1000 * 60 * 5} //1000 * 60 * 5
            onActive={this.handleOnActive}
            onIdle={this.handleOnIdle}
            onAction={this.handleOnAction}
            debounce={250}
            stopOnIdle={true}
        /><BModal className="customModal sessionAlertModal" centered show={this.state.showSessionAlert}>

            <BModal.Body>


                <div className="col-md-12">
                    <div className="row">
                        <div className="modalboxHeader w-100 p-0 sessionAlertBoxHeader">

                            &nbsp;
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="sessionAlertBoxBody">
                            <div className="sessionAlertBoxBodyTop">
                                <div className="customModalIcon pt-3 infoIcon"><i
                                    className="bi bi-hourglass-split m-0"></i></div>
                            </div>
                            <div className="sessionAlertTitle text-center">Your session will expire in:</div>
                            <div className="sessionAlertBoxBodyCountdown mt-3">
                                <h1>{this.state.countdownMinutes}</h1>
                                <h4>min</h4>
                                <h1>{this.state.countdownSeconds}</h1>
                                <h4>secs</h4>
                            </div>
                            <hr className="mt-4"/>
                            <p>Please click ‘Continue’ to
                                stay logged in.</p>
                        </div>


                        <div className="w-100">


                            <div className="sessionAlertBoxBodyButtons">
                                <a href="#" onClick={this.logout}
                                   className="btn-logout">Log Out</a>
                                <a href="#" onClick={this.resetIdleTimer}
                                   className="btn-continue">Continue</a>
                            </div>
                        </div>
                    </div>
                </div>

            </BModal.Body>

        </BModal></div>);
    }

    handleOnAction(event) {
        // console.log('user did something', event)
    }

    handleOnActive(event) {
        // console.log('user is active', event)
        // console.log('time remaining', this.idleTimer.getRemainingTime())
    }

    handleOnIdle(event) {
        // console.log('user is idle', event)
        // console.log('last active', this.idleTimer.getLastActiveTime())

        this.setState({countdownFlag: true}, () => {
            this.setState({showSessionAlert: true}, () => {
                this.setState({countdownMinutes: 2}, () => {
                    this.setState({countdownSeconds: 0}, () => {
                        this.countdown();
                    });
                });
            });
        });

    }


}

export default withRouter(idletimer);
