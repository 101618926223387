import React, {Component} from 'react';
import {Link, Redirect, withRouter} from 'react-router-dom'
import {API_ROOT, DOMAIN, IMAGE_ROOT} from "../api-config";
import 'react-responsive-modal/styles.css';
import axios from "axios";
import $ from "jquery";
import "bootstrap-icons/font/bootstrap-icons.css";
import {Helmet} from "react-helmet";
import Swal from "sweetalert2";
import Dropdown from 'react-bootstrap/Dropdown';

import Topbar from './topbar';
import IdleTimer from './idletimer';

class menu extends Component {

    constructor(props, context) {
        super(props, context);
        console.log(DOMAIN);


        this.state = {
            redirect404: false,
            swalButton: "",
            open: false,
            redirectCorrection:false,

        };

    };

    componentWillMount() {


        if (!localStorage.getItem("emp_id")) {

            sessionStorage.clear();
            this.setState({redirect: true});

        }

        this.setState({
            swalButton: Swal.mixin({
                customClass: {
                    confirmButton: 'btn-yellow',
                    cancelButton: 'btn-yellow'
                },
                buttonsStyling: false,
            })
        });

    }

    componentDidMount() {

        $("body").removeClass("login-page");
        $("body").removeClass("time-clock");


        this.fetchMerchant();

    }

    fetchMerchant = () => {

        var url = API_ROOT + '/fetchmerchant';

        this.setState({loading: true});
        fetch(url)
            .then(response => {
                return response.json();
            })
            .then(merchantData => {

                if (merchantData.status == 1 && merchantData.merchant_data.length > 0) {


                    localStorage.setItem('merchant_id', merchantData.merchant_data[0].merchant_id);
                    localStorage.setItem('merchant_name', merchantData.merchant_data[0].merchant_name);
                    localStorage.setItem('merchant_image', merchantData.merchant_data[0].merchant_image);


                    localStorage.setItem("merchant_front_login_bg", merchantData.merchant_data[0].merchant_front_login_bg);
                    localStorage.setItem("merchant_front_dashboard_bg", merchantData.merchant_data[0].merchant_front_dashboard_bg);
                    localStorage.setItem("merchant_front_timeclock_bg", merchantData.merchant_data[0].merchant_front_timeclock_bg);

                    this.loadProperties();

                } else {

                    this.setState({redirect: false});
                    this.setState({redirect404: true});
                }
            });
    };

    loadProperties = () => {
        var frontLoginBG = localStorage.getItem("merchant_front_login_bg");

        if (frontLoginBG != "") {
            document.documentElement.style.setProperty('--loginBackground', "linear-gradient(0deg, rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)), url(" + IMAGE_ROOT + "/images/profiles/" + frontLoginBG + ")");
        } else {
            document.documentElement.style.setProperty('--loginBackground', "linear-gradient(0deg, rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)), url(../img/login-banner.png)");
        }

        var menuBG = localStorage.getItem("merchant_front_dashboard_bg");

        if (menuBG != "") {
            document.documentElement.style.setProperty('--menuBackground', 'url("' + IMAGE_ROOT + '/images/profiles/' + menuBG + '")');
        } else {
            document.documentElement.style.setProperty('--menuBackground', 'url("../img/announcementbanner.jpeg")');
        }

        var timeClockBG = localStorage.getItem("merchant_front_timeclock_bg");

        if (timeClockBG != "") {
            document.documentElement.style.setProperty('--timeClockBackground', ' url("' + IMAGE_ROOT + '/images/profiles/' + timeClockBG + '") 0 0 no-repeat');
        } else {
            document.documentElement.style.setProperty('--timeClockBackground', ' url("../img/time-clock-banner.jpg") 0 0 no-repeat');
        }
    }

    onOpenModal = () => {
        this.setState({open: true});

    };
    onCloseModal = () => {
        this.setState({open: false});
    };
    openCorrection = () => {

        this.setState({redirectCorrection: true});
    };


    login = e => {


        var user_email = $('#username').val();
        var user_password = $('#password').val();

        var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
        if (user_email === "") {
            this.state.swalButton.fire({
                title: 'Please enter email.',
                allowOutsideClick: false
            });

            return;
        } else if (!pattern.test(user_email)) {

            this.state.swalButton.fire({title: 'Please provide a valid email.', allowOutsideClick: false});
            return;

        } else if (user_password === "") {
            this.state.swalButton.fire({
                title: 'Please enter password.',
                allowOutsideClick: false
            });

            return;
        }


        this.onOpenModal();
        axios.post(API_ROOT + '/login', {
            emp_username: user_email,
            emp_password: user_password,
            merchant_id: localStorage.getItem("merchant_id"),

        })
            .then(response => {

                this.onCloseModal();


                if (response.data.status == 1) {


                    localStorage.setItem('emp_id', response.data.emp_data[0].emp_id);
                    localStorage.setItem('emp_name', response.data.emp_data[0].emp_firstname + " " + response.data.emp_data[0].emp_lastname);
                    localStorage.setItem('emp_email', response.data.emp_data[0].emp_work_email);
                    localStorage.setItem('emp_token', response.data.emp_data[0].emp_token);
                    localStorage.setItem('emp_username', response.data.emp_data[0].emp_username);

                    this.setState({redirect: true});


                } else {
                    this.state.swalButton.fire({
                        title: response.data.message,
                        allowOutsideClick: false
                    });

                }
            }).catch(error => {
            this.onCloseModal();
            console.log(error);
        });


    }

    logout = e => {
        sessionStorage.clear();
        localStorage.removeItem("emp_id");
        localStorage.removeItem("emp_name");
        localStorage.removeItem("emp_email");
        localStorage.removeItem("emp_token");
        localStorage.removeItem("emp_username");
        localStorage.removeItem("emp_image");


        this.setState({redirect: true});
    }
    toProperCase = (txt) => {

        if (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        } else {
            return "";
        }
    }

    render() {

        const {open} = this.state;
        if (this.state.redirect404) {
            return <Redirect to="/404"/>;
        }
        if (this.state.redirect) {
            return <Redirect to="/login"/>;
        }
        if (this.state.redirectCorrection) {

            return <Redirect to="/login"/>;
         //  return <Redirect to={{pathname: '/timeclock', state: {correctionFlag: true}}}/>
        }


        return (<div id="mainBG" className="bg-grey">
            <Helmet>
                <title>{"Home - " + this.toProperCase(localStorage.getItem("merchant_subdomain"))}</title>
            </Helmet>
            <header>

                <IdleTimer/>
                <Topbar page="menu"/>
            </header>

            <main className="home-main">
                <div className="homeAnnouncementMain">
                    <div className="homeAnnouncement">
                        <div className="homeAnnouncementBlock">
                            <div className="Anlogo"></div>
                            <div className="AnnContent">
                                <h3>Announcements</h3>
                                <div className="AnnContentText">
                                    <div className="AnnContentTextLeft float-left">
                                        <img src="/img/home-img1.png" className="img-responsive" alt="Home Image"/>
                                    </div>
                                    <div className="AnnContentTextRight float-left">
                                        <h4 className="open-modal-annocementinfo">401(k) transition information</h4>
                                        <p>Explore your new dashboard and learn more about all of the things happening
                                            at Bryte!</p>
                                    </div>
                                </div>
                                <div className="AnnContentText">
                                    <div className="AnnContentTextLeft float-left">
                                        <img src="/img/home-img2.png" className="img-responsive" alt="Home Image"/>
                                    </div>
                                    <div className="AnnContentTextRight float-left">
                                        <h4>Bryte pool tournament</h4>
                                        <p>Spend an afternoon of intense competition with your peers and friends! The
                                            culmination of I</p>
                                    </div>
                                </div>
                                <a href="#">Previous announcements <i className="icon-arrow-right"></i> </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
                <div className="HomepageBlockMain mb-4">
                    <div className="HomepageBlockInner">
                        <div className="col-xs-12 col-sm-6 pt-3  col-md-6 col-lg-3 HomepageBlockMobileSpacing">
                            <div className="HomepageBlock  ">
                                <div className="HomepageBlock-left">
                                    <div className="HomepageBlockCircle mobile-display-table-cell">
                                        <Link to="/timeclock"><i className="bi bi-hourglass bi-2x"></i></Link>
                                    </div>
                                    <div className="HomepageBlockCircle desktop-block-table-cell">
                                        <i className="bi bi-hourglass bi-2x"></i>
                                    </div>
                                </div>
                                <div className="HomepageBlockText float-left">
                                    <h4>Time Clock</h4>
                                    <hr className="horizontalLine"></hr>
                                    <Link to="/timeclock">Clock In/Out</Link>
                                    <Link to="/timereport">View Time Report</Link>
                                    <Link to={{pathname: "/timeclock", state: {correctionFlag: true}}} >Submit Time Request</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 pt-3  col-md-6 col-lg-3 HomepageBlockMobileSpacing">
                            <div className="HomepageBlock lockParent  ">
                                <div className="HomepageBlock-left">
                                    <div className="HomepageBlockCircle lockIcon">
                                        <i className="bi bi-calendar-range bi-2x"></i>
                                    </div>
                                </div>
                                <div className="HomepageBlockText float-left">
                                    <h4>Benefits</h4>
                                    <hr className="horizontalLine"></hr>
                                    <a href="#">Life Insurance</a>
                                    <a href="#">Paid Time Off</a>
                                    <a href="#">Vacation Time</a>
                                </div>

                                <div className="lock">
                                    <img src="/img/ic_https_24px.png"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 pt-3  col-md-6 col-lg-3 HomepageBlockMobileSpacing">
                            <div className="HomepageBlock lockParent">
                                <div className="HomepageBlock-left">
                                    <div className="HomepageBlockCircle lockIcon">
                                        <i className="bi bi-cash bi-2x"></i>
                                    </div>
                                </div>
                                <div className="HomepageBlockText float-left">
                                    <h4>Finance</h4>
                                    <hr className="horizontalLine"></hr>
                                    <a href="#">401k</a>
                                    <a href="#">Direct Deposit</a>
                                    <a href="#">Pay Rate</a>
                                    <a href="#">View and Print Paystubs</a>
                                </div>
                                <div className="lock">
                                    <img src="/img/ic_https_24px.png"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 pt-3  col-md-6 col-lg-3 HomepageBlockMobileSpacing">
                            <div className="HomepageBlock lockParent  ">
                                <div className="HomepageBlock-left">
                                    <div className="HomepageBlockCircle lockIcon">
                                        <i className="bi bi-graph-up bi-2x"></i>
                                    </div>
                                </div>
                                <div className="HomepageBlockText float-left">
                                    <h4>Performance</h4>
                                    <hr className="horizontalLine"></hr>
                                    <a href="#">Performance Reviews</a>
                                    <a href="#">Ratings</a>
                                    <a href="#">Tardies &amp; Absences</a>
                                    <a href="#">Earned Rewards</a>
                                </div>
                                <div className="lock">
                                    <img src="/img/ic_https_24px.png"/>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6 col-md-6 pt-3 col-lg-3 HomepageBlockMobileSpacing">
                            <div className="HomepageBlock lockParent ">
                                <div className="HomepageBlock-left">
                                    <div className="HomepageBlockCircle lockIcon">
                                        <i className="bi bi-building bi-2x"></i>
                                    </div>
                                </div>
                                <div className="HomepageBlockText float-left">
                                    <h4>Company</h4>
                                    <hr className="horizontalLine"></hr>
                                    <a href="#">Employee Handbook</a>
                                    <a href="#">Leadership Overview</a>
                                </div>
                                <div className="lock">
                                    <img src="/img/ic_https_24px.png"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 pt-3 col-lg-3 HomepageBlockMobileSpacing">
                            <div className="HomepageBlock lockParent ">
                                <div className="HomepageBlock-left">
                                    <div className="HomepageBlockCircle lockIcon">
                                        <i className="bi bi-person-badge bi-2x"></i>
                                    </div>
                                </div>
                                <div className="HomepageBlockText float-left">
                                    <h4>Profile</h4>
                                    <hr className="horizontalLine"></hr>
                                    <a href="#">Edit profile</a>
                                    <a href="#">W4 Exemptions</a>
                                </div>
                                <div className="lock">
                                    <img src="/img/ic_https_24px.png"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 pt-3 col-lg-3 HomepageBlockMobileSpacing">
                            <div className="HomepageBlock lockParent ">
                                <div className="HomepageBlock-left">
                                    <div className="HomepageBlockCircle lockIcon">
                                        <i className="bi bi-diagram-3 bi-2x"></i>
                                    </div>
                                </div>
                                <div className="HomepageBlockText float-left">
                                    <h4>Careers</h4>
                                    <hr className="horizontalLine"></hr>
                                    <a href="#">Advancement Opportunities</a>
                                    <a href="#">Open Job Positions</a>
                                </div>
                                <div className="lock">
                                    <img src="/img/ic_https_24px.png"/>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6 col-md-6 pt-3 col-lg-3 HomepageBlockMobileSpacing">
                            <div className="HomepageBlock lockParent ">
                                <div className="HomepageBlock-left">
                                    <div className="HomepageBlockCircle lockIcon">
                                        <i className="bi bi-heart bi-2x"></i>
                                    </div>
                                </div>
                                <div className="HomepageBlockText float-left">
                                    <h4>Wellness</h4>
                                    <hr className="horizontalLine"></hr>
                                    <a href="#">Physical Wellness</a>
                                    <a href="#">Mental Wellness</a>
                                </div>
                                <div className="lock">
                                    <img src="/img/ic_https_24px.png"/>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <div className="clearfix"></div>
            </main>
            <footer>
                <p>Copyright © 2021 Bryte Payment Solutions.<br/>
                    All Rights Reserved.
                </p>
            </footer>
        </div>);
    }


}

export default withRouter(menu);
