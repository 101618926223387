import React, {Component} from 'react';
import {Link, Redirect, withRouter} from 'react-router-dom'
import {API_ROOT, DOMAIN, IMAGE_ROOT} from "../api-config";
import $ from "jquery";
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import axios from "axios";
import moment from "moment-timezone";

class topbar extends Component {

    constructor(props, context) {
        super(props, context);
        console.log(DOMAIN);

        this.state = {
            redirect: false,
            openDesktopDrawer: false,
            openMobileDrawer: false,
            redirectCorrection: false,
            currentStatus: '',
            currentStatusClass: '',
            currentPage: "",

        }
    };

    logout = () => {
        sessionStorage.clear();
        localStorage.removeItem("emp_id");
        localStorage.removeItem("emp_name");
        localStorage.removeItem("emp_email");
        localStorage.removeItem("emp_token");
        localStorage.removeItem("emp_username");
        localStorage.removeItem("emp_image");

        this.setState({redirect: true});
    }

    componentDidMount() {

        if (this.props.page) {
            this.setState({currentPage: this.props.page});

        }

        //script for drawer
        $(document).ready(function () {

            $("#drawer-section-desktop").hover(function () {
                $("#drawer-section-body-desktop").css('display', 'block');
                $("#caret-arrow").css('transform', 'rotate(180deg)');
                $("#caret-arrow").css('transition', 'transform 0.5s');
            }, function () {
                $("#drawer-section-body-desktop").css('display', 'none');
                $("#caret-arrow").css('transform', 'rotate(0deg)');
                $("#caret-arrow").css('transition', 'transform 0.5s');
            });

            $("#drawer-section-title-mobile").click(function () {

                if ($('#drawer-section-body-mobile').css('display') == 'none') {
                    $("#drawer-section-body-mobile").css('display', 'block');
                    $("#caret-arrow-mobile").css('transform', 'rotate(180deg)');
                    $("#caret-arrow-mobile").css('transition', 'transform 0.5s');
                } else {
                    $("#drawer-section-body-mobile").css('display', 'none');
                    $("#caret-arrow-mobile").css('transform', 'rotate(0deg)');
                    $("#caret-arrow-mobile").css('transition', 'transform 0.5s');
                }
            });

        });

        this.fetchCurrentStatus();

        if (localStorage.getItem("emp_name").indexOf(" ") == -1) {
            $(".setName").html(localStorage.getItem("emp_name"));
        } else {
            $(".setName").html(localStorage.getItem("emp_name").substr(0, localStorage.getItem("emp_name").indexOf(" ")) + " " + localStorage.getItem("emp_name").substr(localStorage.getItem("emp_name").indexOf(" ") + 1, 1) + ".");
        }

    }

    fetchCurrentStatus = () => {

        axios.post(API_ROOT + '/currentStatus', {
            emp_id: localStorage.getItem("emp_id"),
            emp_token: localStorage.getItem("emp_token"),
            merchant_id: localStorage.getItem("merchant_id"),

        })
            .then(response => {

                if (response.data.status == 1) {

                    switch (response.data.current_status) {
                        case 'I':
                        case 'S':
                            localStorage.setItem('current_status', 'IN');
                            break;
                        case 'B':
                            localStorage.setItem('current_status', 'BRK');
                            break;
                        case 'O':
                            localStorage.setItem('current_status', 'OUT');
                            break;
                        default:
                            console.log('Cannot fetch current status');
                            localStorage.setItem('current_status', 'OUT');
                    }

                } else {
                    this.state.swalButton.fire({
                        title: response.data.message,
                        allowOutsideClick: false
                    });

                }
            }).catch(error => {
            this.onCloseModal();
            console.log(error);
        });


    }

    toggleDrawer = () => {

        if ($(window).width() > 767) {
            this.setState({openDesktopDrawer: !this.state.openDesktopDrawer});
            this.setState({openMobileDrawer: false});

            if (this.state.openDesktopDrawer) {
                document.getElementById('header-drawer-icon').classList.add('bi-list');
                document.getElementById('header-drawer-icon').classList.remove('bi-x');
            } else {
                document.getElementById('header-drawer-icon').classList.remove('bi-list');
                document.getElementById('header-drawer-icon').classList.add('bi-x');
            }

        } else {
            this.setState({openMobileDrawer: !this.state.openMobileDrawer});
            this.setState({openDesktopDrawer: false});

            if (this.state.openMobileDrawer) {
                document.getElementById('header-drawer-icon').classList.add('bi-list');
                document.getElementById('header-drawer-icon').classList.remove('bi-x');
            } else {
                document.getElementById('header-drawer-icon').classList.remove('bi-list');
                document.getElementById('header-drawer-icon').classList.add('bi-x');
            }
        }
    }

    openCorrection = () => {

        this.setState({redirectCorrection: true});
    };

    render() {

        if (this.state.redirect) {
            return <Redirect to="/login"/>;
        }
        if (this.state.redirectCorrection) {
            return <Redirect to={{pathname: '/timeclock', state: {correctionFlag: true}}}/>
        }

        return (<div className="header">
            {(this.state.merchant_image != "") ?
                <Link to="/home"><img className="drawer-logo"
                                      src={IMAGE_ROOT + "/images/profiles/" + localStorage.getItem("merchant_image")}
                                      alt="Logo"/></Link> : ''}

            <Drawer
                open={this.state.openDesktopDrawer}
                onClose={() => this.toggleDrawer}
                direction='right'
                className='drawer'
                enableOverlay={false}
                size={320}
            >
                <div className="drawer-content drawer-content-scrollable" role="document">
                    <div className="drawer-body">
                        <div className="drawer-section" id="drawer-section-desktop">
                            <div className="drawer-section-title" id="drawer-section-title-desktop">
                                <div className="drawer-section-title-left">
                                    <i className="bi bi-hourglass"></i>
                                    Time Clock
                                </div>
                                <i className="bi bi-caret-down-fill" id="caret-arrow"></i>
                            </div>
                            <div className="drawer-section-body" id="drawer-section-body-desktop">
                                <Link to="/timeclock">
                                    <div className="drawer-item">Clock In/Out</div>
                                </Link>
                                <Link to="/timereport">
                                    <div className="drawer-item">View Time Report</div>
                                </Link>


                                {(this.state.currentPage == "home") ?
                                    <Link to="#" onClick={this.props.openCorrectionModal}>
                                        <div className="drawer-item">Submit Time Request</div>
                                    </Link> : <Link to={{pathname: "/timeclock", state: {correctionFlag: true}}}>
                                        <div className="drawer-item">Submit Time Request</div>
                                    </Link>}
                                {/*<Link onClick={this.openCorrection}><div className="drawer-item">Submit Time Request</div></Link>*/}
                            </div>
                        </div>
                    </div>

                    <div className="drawer-item-logout" onClick={this.logout}>
                        <i className="bi bi-box-arrow-right"></i>
                        Logout
                    </div>
                </div>
            </Drawer>

            <Drawer
                open={this.state.openMobileDrawer}
                onClose={() => this.toggleDrawer}
                direction='left'
                className='drawer-mob'
                enableOverlay={false}
                size={$(window).width() - 60}
            >
                <div className="drawer-content drawer-content-scrollable" role="document">
                    <div className="drawer-header">
                        <div className="header-icon-container">
                            {localStorage.getItem("emp_image") != "" ?
                                <img src={IMAGE_ROOT + "/images/profiles/" + localStorage.getItem("emp_image")}
                                     alt="Avatar Image"
                                     className="header-profile-img"/> : <img src="/img/avtar-img.png" alt="Avatar Image"
                                                                             className="header-profile-img"/>}
                            <div
                                className={"header-status " + (localStorage.getItem('current_status') == "IN" ? 'header-status-green'
                                    : localStorage.getItem('current_status') == "BRK" ? 'header-status-yellow' : 'header-status-red')}>{localStorage.getItem('current_status')}</div>
                        </div>
                        <div className="header-desk-name">
                            <h3>Welcome!</h3>
                            <h1 className="setName">{localStorage.getItem("emp_name")}</h1>
                        </div>
                    </div>
                    <div className="drawer-body">
                        <div className="drawer-section" id="drawer-section-mobile">
                            <div className="drawer-section-title" id="drawer-section-title-mobile">
                                <div className="drawer-section-title-left">
                                    <i className="bi bi-hourglass"></i>
                                    Time Clock
                                </div>
                                <i className="bi bi-caret-down-fill" id="caret-arrow-mobile"></i>
                            </div>
                            <div className="drawer-section-body" id="drawer-section-body-mobile">
                                <Link to="/timeclock">
                                    <div className="drawer-item">Clock In/Out</div>
                                </Link>
                                <Link to="/timereport">
                                    <div className="drawer-item">View Time Report</div>
                                </Link>
                                <Link to={{pathname: "/timeclock", state: {correctionFlag: true}}}>
                                    <div className="drawer-item">Submit Time Request</div>
                                </Link>
                                {/*<Link onClick={this.openCorrection}><div className="drawer-item">Submit Time Request</div></Link>*/}
                            </div>
                        </div>
                    </div>

                    <div className="drawer-item-logout" onClick={this.logout}>
                        <i className="bi bi-box-arrow-right"></i>
                        Logout
                    </div>

                </div>
            </Drawer>

            <div className="header-right">
                <div className="header-icon-container hideOnMobile">
                    {localStorage.getItem("emp_image") != "" ?
                        <img src={IMAGE_ROOT + "/images/profiles/" + localStorage.getItem("emp_image")}
                             alt="Avatar Image"
                             className="header-profile-img"/> : <img src="/img/avtar-img.png" alt="Avatar Image"
                                                                     className="header-profile-img"/>}
                    {localStorage.getItem("current_status") ? <div
                        className={"header-status " + (localStorage.getItem('current_status') == "IN" ? 'header-status-green'
                            : localStorage.getItem('current_status') == "BRK" ? 'header-status-yellow' : 'header-status-red')}>{localStorage.getItem('current_status')}</div>:""}
                </div>
                <div className="header-desk-name hideOnMobile">
                    <h3>Welcome!</h3>
                    <h1 className="setName">{localStorage.getItem("emp_name")}</h1>
                </div>
                <div className="header-drawer-icon" onClick={this.toggleDrawer}>
                    <i className="bi bi-list" id="header-drawer-icon"></i>
                </div>
            </div>
        </div>);
    }
}

export default withRouter(topbar);
