import React, {Component, useEffect, useState} from 'react';
import {Link, Redirect, withRouter} from 'react-router-dom'
import {API_ROOT, DOMAIN, IMAGE_ROOT} from "../api-config";
import 'react-responsive-modal/styles.css';
import Modal from "react-responsive-modal";
import axios from "axios";
import Swal from "sweetalert2";
import moment from 'moment-timezone';
import 'react-calendar/dist/Calendar.css';
import $ from "jquery";
import "timeclock-hash/jquery-clock-timepicker.js";
// import ReactTooltip from 'react-tooltip';
import {useMediaQuery} from 'react-responsive'
import Calendar from 'react-calendar';

import "react-datepicker/dist/react-datepicker.css";
import BModal from "react-bootstrap/Modal";
import {Helmet} from "react-helmet";
import Topbar from "./topbar";
import IdleTimer from './idletimer';
import {OverlayTrigger} from "react-bootstrap";

let list = [];
let listTillToday = [];

const useCheckMobileScreen = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (width <= 768);
}


const MenuItem = ({day, date, dt, selected}) => {


    if (selected) {
        return <div className="item active-item">
            <a>
                <p>{day}</p>
                <div className="circle">
                    <p>{date}</p>
                </div>
            </a>
        </div>;
    } else {
        return <div className="item">
            <a>
                <p>{day}</p>
                <div className="circle">
                    <p>{date}</p>
                </div>
            </a>
        </div>;
    }
};


export const Menu = (list, selected) =>
    list.map(el => {
        const {day, date, dt} = el;

        return <MenuItem day={day} date={date} key={dt} dt={dt} selected={selected}/>;
    });

const Arrow = ({text, className}) => {
    return <div className={className}>{text}</div>;
};


const Example = ({payPeriodFrom, payPeriodTo, preSelectedDate, currentStep, currentView, callback, setCurrentView}) => {

    const isMobile = useMediaQuery({query: '(max-width: 590px)'});


    var list = [];


    if (isMobile) {

        if (currentView == "D") {
            setCurrentView("M");
            currentStep = 0;
        }

        var from = payPeriodFrom.clone().add(currentStep * 3, 'days');

        var to = payPeriodTo.clone();

        for (var i = 0; i < 3; i++) {

            list.push({
                day: from.format("dddd"),
                date: from.format("DD"),
                dt: from.format("YYYY-MM-DD")
            })

            from = from.add(1, 'day');
            if (from > to) {
                break;
            }
        }

    } else {
        if (currentView == "M") {
            setCurrentView("D");
            currentStep = 0;
        }


        var from = payPeriodFrom.clone().add(currentStep * 7, 'days');

        var to = payPeriodTo.clone();

        for (var i = 0; i < 7; i++) {

            list.push({
                day: from.format("dddd"),
                date: from.format("DD"),
                dt: from.format("YYYY-MM-DD")
            })

            from = from.add(1, 'day');
            if (from > to) {
                break;
            }
        }

    }


    return <div>{list.map((item, index) => {


        return ((preSelectedDate === item.dt) ? <div className="item active">

            <p>{item.day}</p>
            <li>
                <input type="radio" id={index} name="amount"
                       checked={preSelectedDate === item.dt}
                       onChange={() => callback(item.dt)}/>
                <label htmlFor={index}>{item.date}</label>
            </li>

        </div> : <div className="item">

            <p>{item.day}</p>
            <li>
                <input type="radio" id={index} name="amount"
                       checked={preSelectedDate === item.dt}
                       onChange={() => callback(item.dt)}/>
                <label htmlFor={index}>{item.date}</label>
            </li>

        </div>);
    })
    }
    </div>


}

export const ArrowLeft = <div className="owl-nav"><i className="icon-angle-left"></i></div>;
export const ArrowRight = <div className="owl-nav"><i className="icon-angle-right"></i></div>;


class home extends Component {


    constructor(props, context) {
        super(props, context);
        console.log(DOMAIN);
        this.targetOverlay = null;

        this.state = {
            redirect404: false,
            redirect: false,
            swalButton: "",
            open: false,
            timeRequestModalDropdown: false,
            timeRequestModalTimeline: false,
            timeRequestModalEditHour: false,
            timeRequestModalEditTime: false,
            timeRequestModalAddTime: false,
            showModalOutsideGeofence: false,
            ptoModal: false,
            vacationModal: false,
            modalOutsideGeofenceMsg: "",
            currentView: "D",
            showEventAlert: false,
            showAlert: false,
            alertTitle: "",
            alertDesc: "",
            eventAlertType: "successModal",
            showCorrectionAlert: false,
            loading: 'Loading',
            currentTime: "",
            currentTimeAMPM: "",
            currentDate: "",
            currentPayWeek: 0,
            step: 0,
            eventTime: "",
            eventTimeAMPM: "",
            eventDate: "",
            eventStatus: "",
            payPeriodFrom: "",
            payPeriodTo: "",
            currentStatus: "",
            todayMinutes: "0",
            dailyTotalHours: "0",
            dailyHours: "0",
            dailyMinutes: "0",
            projectedTotalHours: "0",
            projectedHours: "0",
            projectedMinutes: "0",
            weeklyTotalHours: "0",
            weeklyHours: "0",
            weeklyMinutes: "0",
            payPeriod: "",
            selectedDate: "",
            dateList: [],
            dateHours: "0",
            timeline: [],
            editHourTimeline: [],
            editHourDateSelected: "",
            correctionTimelines: [],
            corrections: [],
            payPeriodList: [],
            payPeriodListTillToday: [],
            corectionTimes: [],
            correctionDates: [],
            timesheetflag: false,
            time: "",
            date: "",
            showNext: true,
            showPrevious: true,
            preSelectedDate: "",
            alignCenter: true,
            clickWhenDrag: false,
            dragging: true,
            hideArrows: true,
            hideSingleArrow: true,
            itemsCount: list.length,
            scrollToSelected: true,
            selected: "2021-07-02",
            translate: 0,
            transition: 0.3,
            wheel: true,
            openCorrection: false,
            projectedOut: "--:-- --",
            lastEventTime: "",
            timepickerOnChange: "",
            timepickerValue: "10:00",
            isShiftStartDisable: false,
            isShiftEndDisable: false,
            isLunchStartDisable: false,
            isLunchEndDisable: false,
            showOverlay: false,
            selectedPtoStartDate: "",
            selectedPtoEndDate: "",
            selectedVacationStartDate: "",
            selectedVacationEndDate: "",
            showVacationValidation: false,
            showPtoValidation: false,
            showCorrValidation: false,
            vacationValidationMsg: "",
            ptoValidationMsg: "",
            corrValidationMsg: "",
            vacationBalance: 0,
            ptoBalance: 0,
            pendingVacationCount: 0,
            pendingPtoCount: 0,
            ptoDuration: 8,
            vacationDuration: 8,
            pto_from: moment().format('YYYY-MM-DD HH:mm:ss'),
            showPtoOption: false,
            showVacationOption: false,
            showSubmitAlert: false,
            reports_to: "",
            ptoBorrowingFlag: false
        };

        this.menu = null;

    };

    componentDidUpdate(prevProps, prevState) {
        const {alignCenter} = prevState;
        const {alignCenter: alignCenterNew} = this.state;
        if (alignCenter !== alignCenterNew) {
            this.menu.setInitial();
        }
    }

    strip_tags = (input, allowed) => {
        if (input != null) {
            allowed = (((allowed || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('')
            var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi
            var commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi
            return input.replace(commentsAndPhpTags, '').replace(tags, function ($0, $1) {
                return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : ''
            })
        } else {
            return "";
        }
    }

    componentWillMount() {

        if (!localStorage.getItem("emp_id")) {

            this.setState({redirect: true});
        }
        this.setState({
            swalButton: Swal.mixin({
                customClass: {
                    title: 'dialogTitle',
                    confirmButton: 'dialogButton',
                    cancelButton: 'dialogButton'
                },
                buttonsStyling: false,
            })
        });

    }

    componentDidMount() {

        $("body").removeClass("login-page");


        this.loadProperties();

        $('.item a').click(function (e) {
            e.preventDefault();

        });

        this.showTime();
        this.fetchDashboard(true);

        $(document).mouseup(function (e) {
            var container = $(".ptoStartDatePicker,.ptoEndDatePicker,.ptoStartTimePicker,.ptoEndTimePicker,.vacationStartDatePicker,.vacationEndDatePicker,.vacationStartTimePicker,.vacationEndTimePicker");
            var containerBox = $("#ptoStartDateBox,#ptoEndDateBox,#ptoStartTimeBox,#ptoEndTimeBox,#vacationStartDateBox,#vacationEndDateBox,#vacationStartTimeBox,#vacationEndTimeBox");

            // if the target of the click isn't the container nor a descendant of the container
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.removeClass('active');
                containerBox.removeClass('active');
            }
        });

    }

    movePrevious = () => {

        var current = this.state.currentPayWeek;


        if (current > 0) {
            current--;
            this.setState({currentPayWeek: current})
            this.setState({showNext: true});
        }

        if (current == 0) {
            this.setState({showPrevious: false});
        }

    };

    moveNext = () => {

        var current = this.state.currentPayWeek;

        var currentView = this.state.currentView;

        if (currentView == "D") {


            if (current < this.state.dateList.length - 1) {
                current++;
                this.setState({currentPayWeek: current});

                this.setState({showPrevious: true});
            }

            if (current == this.state.dateList.length - 1) {
                this.setState({showNext: false});
            }
        } else {

            var list = this.state.payPeriodList;


            var totalStep = Math.ceil(list.length / 3);

            if (current < totalStep - 1) {
                current++;
                this.setState({currentPayWeek: current});

                this.setState({showPrevious: true});
            }

            if (current == totalStep - 1) {
                this.setState({showNext: false});
            }

        }

    };

    loadProperties = () => {
        var frontLoginBG = localStorage.getItem("merchant_front_login_bg");

        if (frontLoginBG != "") {
            document.documentElement.style.setProperty('--loginBackground', "linear-gradient(0deg, rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)), url(" + IMAGE_ROOT + "/images/profiles/" + frontLoginBG + ")");
        } else {
            document.documentElement.style.setProperty('--loginBackground', "linear-gradient(0deg, rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)), url(../img/login-banner.png)");
        }

        var menuBG = localStorage.getItem("merchant_front_dashboard_bg");

        if (menuBG != "") {
            document.documentElement.style.setProperty('--menuBackground', 'url("' + IMAGE_ROOT + '/images/profiles/' + menuBG + '")');
        } else {
            document.documentElement.style.setProperty('--menuBackground', 'url("../img/announcementbanner.jpeg")');
        }

        var timeClockBG = localStorage.getItem("merchant_front_timeclock_bg");

        if (timeClockBG != "") {
            document.documentElement.style.setProperty('--timeClockBackground', ' url("' + IMAGE_ROOT + '/images/profiles/' + timeClockBG + '") 0 0 no-repeat');
        } else {
            document.documentElement.style.setProperty('--timeClockBackground', ' url("../img/time-clock-banner.jpg") 0 0 no-repeat');
        }
    }

    onFirstItemVisible = () => {
        console.log("first item is visible");
    };

    onLastItemVisible = () => {
        console.log("last item is visible");


    };

    onDateSelected = (value) => {


        this.setState({preSelectedDate: moment(value).format("YYYY-MM-DD")});
        this.fetchTimeline(moment(value).format("YYYY-MM-DD"), 0, "T");


    }

    showTime = () => {

        var currentTime = moment().format("h:mm");
        var currentTimeAMPM = moment().format("a")
        var currentDate = moment().format("MMM DD, YYYY");

        this.setState({currentTimeAMPM: currentTimeAMPM});
        this.setState({currentTime: currentTime});
        this.setState({currentDate: currentDate});

        setTimeout(this.showTime, 1000);

    }

    onOpenModal = (msg) => {

        this.setState({loading: msg});
        this.setState({open: true});

    };

    onCloseModal = () => {
        this.setState({open: false});
    };

    logout = e => {
        localStorage.clear();
        this.setState({redirect: true});
    }


    fetchDashboard = (flag) => {

        if (flag) {
            this.onOpenModal("Loading");
        }

        axios.post(API_ROOT + '/dashboard', {
            emp_id: localStorage.getItem("emp_id"),
            emp_token: localStorage.getItem("emp_token"),
            merchant_id: localStorage.getItem("merchant_id"),

        })
            .then(response => {

                this.onCloseModal();

                if (response.data.status == 1) {

                    if (response.data.current_status === "I" || response.data.current_status === "S") {
                        localStorage.setItem('current_status', 'IN');
                    } else if ((response.data.current_status === "B")) {
                        localStorage.setItem('current_status', 'BRK');
                    } else if ((response.data.current_status === "O")) {
                        localStorage.setItem('current_status', 'OUT');
                    } else {
                        localStorage.setItem('current_status', '');
                        console.log("Unable to fetch current status in time clock page")
                    }

                    this.setState({currentStatus: response.data.current_status});
                    this.setState({dailyTotalHours: response.data.total_hours});
                    this.setState({weeklyTotalHours: response.data.weekly_hours});
                    this.setState({payPeriod: response.data.pay_period});
                    this.setState({projectedTotalHours: response.data.projected_hours});
                    this.setState({todayMinutes: response.data.today_minutes});
                    this.setState({projectedOut: response.data.projected_out});
                    this.setState({lastEventTime: response.data.last_event_time});

                    this.setState({dailyHours: Math.floor(response.data.total_hours / 60)});
                    this.setState({dailyMinutes: Math.floor(response.data.total_hours % 60)});

                    this.setState({weeklyHours: Math.floor(response.data.weekly_hours / 60)});
                    this.setState({weeklyMinutes: Math.floor(response.data.weekly_hours % 60)});

                    this.setState({projectedHours: Math.floor(response.data.projected_hours / 60)});
                    this.setState({projectedMinutes: Math.floor(response.data.projected_hours % 60)});


                    list = [];
                    listTillToday = [];

                    var main = [];

                    var periodFrom = moment(response.data.pay_period_from, "YYYY-MM-DD");
                    var periodTo = moment(response.data.pay_period_to, "YYYY-MM-DD");


                    this.setState({payPeriodFrom: periodFrom});
                    this.setState({payPeriodTo: periodTo});


                    var from = periodFrom.clone();
                    var to = periodTo.clone();

                    while (from < to) {

                        list.push({
                            day: from.format("dddd"),
                            date: from.format("DD"),
                            dt: from.format("YYYY-MM-DD")
                        })

                        from = from.add(1, 'day');


                    }

                    this.setState({payPeriodList: list});

                    from = periodFrom.clone();
                    var today = moment();

                    while (from < today) {

                        listTillToday.push({
                            day: from.format("dddd"),
                            date: from.format("DD"),
                            month: from.format("MMM"),
                            dt: from.format("YYYY-MM-DD")
                        })

                        from = from.add(1, 'day');
                    }

                    //reverse the order
                    listTillToday.reverse();
                    this.setState({payPeriodListTillToday: listTillToday});
                    console.log("THIS IS THE ARRAY :-");
                    console.log(listTillToday);


                    var sub = [];
                    var count = 0;

                    var foundFlag = 0;

                    this.setState({preSelectedDate: moment().format("YYYY-MM-DD")});

                    while (count < list.length) {

                        sub.push(list[count]);


                        if (list[count].dt == moment().format("YYYY-MM-DD")) {
                            foundFlag = 1;

                        }
                        count++;


                        if (count % 7 == 0) {

                            if (foundFlag == 1) {
                                this.setState({currentPayWeek: main.length});
                            }
                            main.push(sub);
                            sub = [];
                            foundFlag = 0;
                        }

                    }


                    if (sub.length > 0) {
                        main.push(sub);
                    }

                    console.log(main);


                    if (this.state.currentPayWeek == 0) {

                        this.setState({showPrevious: false});
                    }

                    if (this.state.currentPayWeek == main.length - 1) {

                        this.setState({showNext: false});
                    }

                    this.setState({dateList: main});
                    this.setState({selected: moment().format("YYYY-MM-DD")});


                    if (this.props.location.state) {
                        if (this.props.location.state.correctionFlag) {

                            this.openCorrectionModal();
                        }
                    }


                } else {
                    this.state.swalButton.fire({
                        title: response.data.message,
                        allowOutsideClick: false
                    });

                }
            }).catch(error => {
            this.onCloseModal();
            console.log(error);
        });


    }


    fetchTimeline = (date, index, flag) => {


        this.onOpenModal("Loading");

        axios.post(API_ROOT + '/timelinebydate', {
            emp_id: localStorage.getItem("emp_id"),
            emp_token: localStorage.getItem("emp_token"),
            merchant_id: localStorage.getItem("merchant_id"),
            timeline_date: date

        })
            .then(response => {

                this.onCloseModal();

                if (response.data.status == 1) {


                    if (flag === "T") {

                        var timeline = response.data.timeline;

                        this.setState({dateHours: response.data.total_hours});
                        this.setState({timeline: timeline});

                    } else if (flag === "C") {

                        var corrections = this.state.corrections;
                        corrections[index].timelines = response.data.timeline;
                        corrections[index].timeline_id = response.data.timeline[0].timeline_id;

                        this.setState({corrections: corrections});

                    } else if (flag === "M") {

                        var editHourTimeline = response.data.timeline;


                        var ClockInFlag = false, ClockOutFlag = false, LunchInFlag = false, LunchOutFlag = false;
                        for (var i = 0; i < editHourTimeline.length; i++) {
                            if (editHourTimeline[i].timeline_event === "I") {
                                ClockInFlag = true;
                            } else if (editHourTimeline[i].timeline_event === "B") {
                                LunchInFlag = true;
                            } else if (editHourTimeline[i].timeline_event === "S") {
                                LunchOutFlag = true;
                            } else if (editHourTimeline[i].timeline_event === "O") {
                                ClockOutFlag = true;
                            }
                            editHourTimeline[i].timeline_corr_time = editHourTimeline[i].timeline_time;
                            editHourTimeline[i].timeline_show_validation = false;
                            editHourTimeline[i].timeline_show_validation_dialog = false;
                            editHourTimeline[i].timeline_show_validation_message = "";
                        }

                        if (!ClockInFlag) {
                            editHourTimeline.push({
                                timeline_event: "I",
                                timeline_id: "",
                                timeline_time: "",
                                timeline_corr_time: "",
                                timeline_value: ""
                            })
                        }

                        if (!LunchInFlag) {
                            editHourTimeline.push({
                                timeline_event: "B",
                                timeline_id: "",
                                timeline_time: "",
                                timeline_corr_time: "",
                                timeline_value: ""
                            })
                        }

                        if (!LunchOutFlag) {
                            editHourTimeline.push({
                                timeline_event: "S",
                                timeline_id: "",
                                timeline_time: "",
                                timeline_corr_time: "",
                                timeline_value: ""
                            })
                        }

                        if (!ClockOutFlag) {
                            editHourTimeline.push({
                                timeline_event: "O",
                                timeline_id: "",
                                timeline_time: "",
                                timeline_corr_time: "",
                                timeline_value: ""
                            })
                        }

                        this.setState({editHourTimeline: editHourTimeline});
                    }


                } else {
                    this.state.swalButton.fire({
                        title: response.data.message,
                        allowOutsideClick: false
                    });

                }
            }).catch(error => {
            this.onCloseModal();
            console.log(error);
        });


    }

    onTimelineDateSelected = (date) => {
        this.fetchTimeline(moment(date).format("YYYY-MM-DD"), 0, "M");
        this.setState({editHourDateSelected: date});
        this.setState({timeRequestModalTimeline: false});
        this.setState({timeRequestModalEditHour: true});
        this.setState({showCorrValidation: false});
        this.setState({corrValidationMsg: ""});
    }

    openCorrectionModal = () => {
        //search here;


        this.onOpenModal("Loading");

        axios.post(API_ROOT + '/checkPtoAndVacation', {
            emp_id: localStorage.getItem("emp_id"),
            emp_token: localStorage.getItem("emp_token"),
            merchant_id: localStorage.getItem("merchant_id"),
        })
            .then(response => {

                this.onCloseModal();

                if (response.data.status == 1) {

                    localStorage.setItem('emp_status', response.data.data.emp_status);

                    if (response.data.data.emp_status == "F") {

                        if (response.data.data.merchant_full_pto_enabled == "Y") {

                            this.setState({showPtoOption: true});
                        } else {

                            this.setState({showPtoOption: false});
                        }


                        if (response.data.data.merchant_full_vacation_enabled == "Y") {

                            this.setState({showVacationOption: true});
                        } else {

                            this.setState({showVacationOption: false});
                        }

                    } else {

                        if (response.data.data.merchant_part_pto_enabled == "Y") {

                            this.setState({showPtoOption: true});
                        } else {

                            this.setState({showPtoOption: false});
                        }

                        if (response.data.data.merchant_part_vacation_enabled == "Y") {

                            this.setState({showVacationOption: true});
                        } else {

                            this.setState({showVacationOption: false});
                        }

                    }
                    this.setState({timeRequestModalDropdown: true});
                    //console.log(this.state.showPtoOption + " " + this.state.showVacationOption);

                } else {
                    this.state.swalButton.fire({
                        title: response.data.message,
                        allowOutsideClick: false
                    });

                }

            }).catch(error => {
            this.onCloseModal();
            console.log(error);
        });
    }
    handleEventClose = e => {

        this.setState({showEventAlert: false});
        this.setState({showAlert: false});
    }
    openEventAlert = e => {

        this.setState({showEventAlert: true});
    }
    handleSubmitClose = e => {

        this.setState({showSubmitAlert: false});
    }
    timeCorrectionModalOpen = () => {
        this.setState({timeRequestModalDropdown: false});
        this.setState({timeRequestModalTimeline: true});
    }

    handleCloseTimeRequestModalDropdown = e => {
        this.setState({corrections: []});
        this.setState({timeRequestModalDropdown: false});
    }

    handleBackModalTimeline = () => {
        this.setState({timeRequestModalTimeline: false});
        this.setState({timeRequestModalDropdown: true});
    }

    handleCloseTimeRequestModalTimeline = e => {
        this.setState({timeRequestModalTimeline: false});
    }

    openModalOutsideGeofence = (msg) => {
        this.setState({modalOutsideGeofenceMsg: msg});
        this.setState({showModalOutsideGeofence: true});
    }

    closeModalOutsideGeofence = e => {
        this.setState({showModalOutsideGeofence: false});
    }

    handleBackModalEditHour = () => {
        this.setState({timeRequestModalEditHour: false});
        this.setState({timeRequestModalTimeline: true});
    }

    handleCloseTimeRequestModalEditHour = e => {
        this.setState({timeRequestModalEditHour: false});
        this.setState({showCorrValidation: false});
        this.setState({corrValidationMsg: ""});
    }

    openTimeRequestModalEditTime = (actualTime, corTime, status, index) => {
        this.setState({timeRequestModalEditTime: true});
        var duplicateThis = this;
        console.log("Actual Time : " + actualTime + " Cortime : " + corTime + " Status : " + status + " Index : " + index);

        setTimeout(function () {

            var onlyTime, onlyAmPm, formattedActualTime;

            if (actualTime == "") {
                formattedActualTime = "--:-- --";
            } else {
                formattedActualTime = moment(actualTime).format("hh:mma");
            }

            if (corTime == "") {
                onlyTime = "12:00"; //for default value
            } else {
                onlyTime = moment(corTime).format("hh:mm");
            }

            if (corTime == "") {
                onlyAmPm = "am"; //for default value
            } else {
                onlyAmPm = moment(corTime).format("a");
            }

            window.$('.clockTimePickerSubTitle').html(status);
            window.$('.time_picker').val(onlyTime);
            window.$("input[name=ampm][value=" + onlyAmPm + "]").prop('checked', true);
            window.$('.time_picker').clockTimePicker({
                precision: 1,
                minimum: '1:00',
                maximum: '12:59'
                // onAdjust: function (newVal, oldVal) {
                //     console.log(newVal + " " + oldVal);
                // }
            });

            window.$('.editTimeOkBtn').click(function () {
                var newTime = window.$('.time_picker').val();
                var newAmPm = window.$("input[name=ampm]:checked").val();
                console.log("Actual Time : " + actualTime + " Cortime : " + corTime + " Status : " + status + " Index : " + index);
                console.log("Time Obtained : " + newTime + newAmPm);
                console.log(moment(duplicateThis.state.editHourDateSelected).format('YYYY-MM-DD').replace(/-/g, "/") + " " + newTime.replace(/-/g, "/") + " " + newAmPm.replace(/-/g, "/"));

                var editHourTimeline = duplicateThis.state.editHourTimeline;
                var newCorrTime = moment(moment(duplicateThis.state.editHourDateSelected).format('YYYY-MM-DD').replace(/-/g, "/") + " " + newTime.replace(/-/g, "/") + " " + newAmPm.replace(/-/g, "/")).format('YYYY-MM-DD HH:mm:ss');
                var flag = false;

                for (var i = 0; i < editHourTimeline.length; i++) {
                    console.log(editHourTimeline[i].timeline_corr_time + " " + newCorrTime);
                    if (editHourTimeline[i].timeline_corr_time === newCorrTime) {
                        flag = true;
                    }
                }

                if (flag) {
                    console.log("SAME TIME DETECTED IN TIMELINES!" + newCorrTime);
                    editHourTimeline[index].timeline_show_validation_dialog = true;
                    editHourTimeline[index].timeline_show_validation = true;
                    editHourTimeline[index].timeline_show_validation_message = "same";
                    duplicateThis.setState({editHourTimeline: editHourTimeline});
                    duplicateThis.handleCloseTimeRequestModalEditTime();
                    duplicateThis.setState({showCorrValidation: true});
                    duplicateThis.setState({corrValidationMsg: "Please choose a different time for " + duplicateThis.getEventName(editHourTimeline[index].timeline_event) + "."});
                } else {
                    if (moment(newCorrTime) > moment()) {
                        console.log("FUTURE TIME SELECTED!" + moment(newCorrTime) + " " + moment());
                        editHourTimeline[index].timeline_show_validation_dialog = true;
                        editHourTimeline[index].timeline_show_validation = true;
                        editHourTimeline[index].timeline_show_validation_message = "";
                        duplicateThis.setState({editHourTimeline: editHourTimeline});
                        duplicateThis.sortEditHourTimeline();
                        duplicateThis.handleCloseTimeRequestModalEditTime();
                        duplicateThis.setState({showCorrValidation: true});
                        duplicateThis.setState({corrValidationMsg: "Your " + duplicateThis.getEventName(editHourTimeline[index].timeline_event) + " cannot be at a future time. Please adjust the time to be at or prior to " + moment().format('hh:mmA') + "."});
                    } else {
                        duplicateThis.removeAllErrorIndicators();
                        if (formattedActualTime === newCorrTime) {
                            console.log("Time Same : " + actualTime + " & " + newCorrTime);
                            duplicateThis.handleCloseTimeRequestModalEditTime();
                        } else {
                            console.log("Time NOT Same : " + actualTime + " & " + newCorrTime);
                            editHourTimeline[index].timeline_corr_time = newCorrTime;
                            duplicateThis.setState({editHourTimeline: editHourTimeline});
                            duplicateThis.sortEditHourTimeline();
                            duplicateThis.handleCloseTimeRequestModalEditTime();
                        }
                    }
                }

            });

        }, 200);

    }

    clearTimeModalEditHour = (index) => {
        var editHourTimeline = this.state.editHourTimeline;
        editHourTimeline[index].timeline_show_validation = false;
        editHourTimeline[index].timeline_show_validation_message = "";
        if (editHourTimeline[index].timeline_corr_time !== "") {
            editHourTimeline[index].timeline_corr_time = "";
            this.setState({editHourTimeline: editHourTimeline});
            this.sortEditHourTimeline();
        }
    }

    closeErrorPopper = (index) => {
        var editHourTimeline = this.state.editHourTimeline;
        editHourTimeline[index].timeline_show_validation_dialog = false;
        //editHourTimeline[index].timeline_show_validation_message = "";
        this.setState({editHourTimeline: editHourTimeline});
        this.sortEditHourTimeline();

    }

    openErrorPopper = (index) => {
        var editHourTimeline = this.state.editHourTimeline;
        if (editHourTimeline[index].timeline_show_validation_dialog === false) {
            editHourTimeline[index].timeline_show_validation_dialog = true;
        } else {
            editHourTimeline[index].timeline_show_validation_dialog = false;
        }
        this.setState({editHourTimeline: editHourTimeline});
        this.sortEditHourTimeline();
    }

    handleCloseTimeRequestModalEditTime = e => {
        this.setState({timeRequestModalEditTime: false});
    }

    sortEditHourTimeline = () => {
        var editHourTimeline = this.state.editHourTimeline;
        editHourTimeline.sort((a, b) => {
            if (a.timeline_corr_time === b.timeline_corr_time) {
                return 0;
            } else if (a.timeline_corr_time === "") {
                return 1;
            } else if (b.timeline_corr_time === "") {
                return -1;
            } else if (a.timeline_corr_time > b.timeline_corr_time) {
                return 1;
            } else {
                return -1;
            }
        });
        this.setState({editHourTimeline: editHourTimeline});
        console.log(editHourTimeline);
    }

    removeAllErrorIndicators = () => {
        var editHourTimeline = this.state.editHourTimeline;
        for (var i = 0; i < editHourTimeline.length; i++) {
            editHourTimeline[i].timeline_show_validation = false;
            editHourTimeline[i].timeline_show_validation_dialog = false;
            editHourTimeline[i].timeline_show_validation_message = "";
        }
        this.setState({editHourTimeline: editHourTimeline});
        this.setState({showCorrValidation: false});
        this.setState({corrValidationMsg: ""});
    }

    openTimeRequestModalAddTime = () => {
        this.setState({timeRequestModalAddTime: true});

        // var editHourTimeline = this.state.editHourTimeline;
        // var ClockInCount = 0, ClockOutCount = 0, LunchInCount = 0, LunchOutCount = 0;
        //
        // for (var i = 0; i < editHourTimeline.length; i++) {
        //     if (editHourTimeline[i].timeline_event === "I") {
        //         ClockInCount++;
        //     } else if (editHourTimeline[i].timeline_event === "B") {
        //         LunchInCount++;
        //     } else if (editHourTimeline[i].timeline_event === "S") {
        //         LunchOutCount++;
        //     } else if (editHourTimeline[i].timeline_event === "O") {
        //         ClockOutCount++;
        //     }
        // }
        //
        // console.log(ClockInCount + " " + ClockOutCount + " " + LunchInCount + " " + LunchOutCount);
        //
        // if (ClockInCount !== ClockOutCount) {
        //     this.setState({isShiftStartDisable: true});
        //     this.setState({isShiftEndDisable: false});
        // } else {
        //     this.setState({isShiftStartDisable: false});
        //     this.setState({isShiftEndDisable: true});
        // }
        //
        // if (LunchInCount !== LunchOutCount) {
        //     this.setState({isLunchStartDisable: true});
        //     this.setState({isLunchEndDisable: false});
        // } else {
        //     this.setState({isLunchStartDisable: false});
        //     this.setState({isLunchEndDisable: true});
        // }

    }

    handleCloseTimeRequestModalAddTime = e => {
        this.setState({timeRequestModalAddTime: false});
    }

    handleNextTimeRequestModalAddTime = e => {

        var value = $("input[type='radio'][name='addTimeRadioButton']:checked").val();

        if (value) {
            var editHourTimeline = this.state.editHourTimeline;

            editHourTimeline.push({
                timeline_event: value,
                timeline_id: "",
                timeline_time: "",
                timeline_corr_time: "",
                timeline_value: ""
            })

            this.setState({editHourTimeline: editHourTimeline});
            this.setState({timeRequestModalAddTime: false});
        }
    }

    /*getGeolocation = () => {
        //FOR TESTING
        var dummyThis = this;
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function(position) {
                console.log("Latitude is :", position.coords.latitude);
                console.log("Longitude is :", position.coords.longitude);
                dummyThis.setState({latitude: position.coords.latitude});
                dummyThis.setState({longitude: position.coords.longitude});
            }, function (error) {
                dummyThis.state.swalButton.fire({
                    title: error,
                    allowOutsideClick: false
                })
            });
        } else {
            console.log("Geolocation Not Available In Browser");
        }
    }*/

    getGeolocation = (status) => {

        if (status == "I") {
            this.onOpenModal("Clocking In")
        } else if (status == "O") {
            this.onOpenModal("Clocking Out")
        } else if (status == "B") {
            this.onOpenModal("Starting Lunch Break")
        } else if (status == "S") {
            this.onOpenModal("Ending Lunch Break")
        }

        var dummyThis = this;
        var lat, lng;

        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                console.log("Latitude is :", position.coords.latitude);
                console.log("Longitude is :", position.coords.longitude);
                lat = position.coords.latitude;
                lng = position.coords.longitude;
                console.log("lat : " + lat + "lng : " + lng);
                dummyThis.createEvent(status, lat, lng);

            }, function (error) {
                console.log(error);
                dummyThis.createEvent(status, 0, 0);
            });
        } else {
            console.log("Geolocation Not Available In Browser");
            dummyThis.createEvent(status, 0, 0);

        }


    }

    createEvent = (status, lat, lng) => {

        var dummyThis = this;

        axios.post(API_ROOT + '/createevent', {
            emp_id: localStorage.getItem("emp_id"),
            emp_token: localStorage.getItem("emp_token"),
            merchant_id: localStorage.getItem("merchant_id"),
            event_type: status,
            latitude: lat + "",
            longitude: lng + "",
            // latitude: "22.297993343368027",
            // longitude: "73.20215811236",
        })
            .then(response => {

                dummyThis.onCloseModal();

                if (response.data.status == 1) {


                    dummyThis.setState({eventTime: dummyThis.state.currentTime});
                    dummyThis.setState({eventTimeAMPM: dummyThis.state.currentTimeAMPM});
                    dummyThis.setState({eventDate: dummyThis.state.currentDate});

                    if (status == "I") {
                        dummyThis.setState({eventStatus: "You are clocked in!"});
                        dummyThis.setState({eventAlertType: "successModal"});
                    } else if (status == "O") {
                        dummyThis.setState({eventStatus: "You are clocked out!"});
                        dummyThis.setState({eventAlertType: "errorModal"});
                    } else if (status == "B") {

                        dummyThis.setState({eventStatus: "You are on lunch break!"});
                        dummyThis.setState({eventAlertType: "warningModal"});
                    } else if (status == "S") {
                        dummyThis.setState({eventAlertType: "successModal"});
                        dummyThis.setState({eventStatus: "You are clocked in!"});
                    }


                    dummyThis.openEventAlert();
                    dummyThis.setState({currentStatus: status});
                    dummyThis.fetchDashboard(false);


                } else {

                    if (response.data.message === "L") {
                        dummyThis.openModalOutsideGeofence("Your company requires you to be within a geofence boundary to use the time clock. Please make sure you are within range of your work location.");
                    } else if (response.data.message === "D" || response.data.message === "I") {
                        dummyThis.openModalOutsideGeofence("Your company requires you to be within a company intranet to use the time clock. Please make sure you are connected to your work location's network.");
                    } else {

                        dummyThis.setState({showAlert: true});
                        dummyThis.setState({alertTitle: response.data.message});
                        if (response.data.desc) {
                            dummyThis.setState({alertDesc: response.data.desc});
                        } else {
                            dummyThis.setState({alertDesc: ""});
                        }

                    }

                }
            }).catch(error => {
            dummyThis.onCloseModal();
            console.log(error);
        });
    }

    setTime = (date, index) => {


        var corrections = this.state.corrections;


        corrections[index].corrected_time = date;
        this.setState({corrections: corrections});


    }
    removeCorrection = (index) => {


        var corrections = this.state.corrections;

        corrections.splice(index, 1);

        this.setState({corrections: corrections});
    }
    setDate = (date, index) => {


        var corrections = this.state.corrections;


        corrections[index].correction_date = date;
        this.setState({corrections: corrections});


        this.fetchTimeline(moment(date).format("YYYY-MM-DD"), index, "C");
    }

    submitCorrectionOld = e => {

        var corrections = this.state.corrections;
        var cor = [];
        console.log(corrections);
        for (var i = 0; i < corrections.length; i++) {

            if (corrections[i].correction_date == "") {
                this.state.swalButton.fire({
                    title: 'Please select date.',
                    allowOutsideClick: false
                });

                return;
            } else if (corrections[i].corrected_time == "") {
                this.state.swalButton.fire({
                    title: 'Please select time.',
                    allowOutsideClick: false
                });

                return;
            }
        }


        this.onOpenModal("Submitting Correction");


        for (var i = 0; i < corrections.length; i++) {


            corrections[i].timelines = [];

            var date = moment(corrections[i].correction_date).format("YYYY-MM-DD");
            var time = moment(corrections[i].corrected_time).format("HH:mm:ss");
            var timelineid = corrections[i].timeline_id;
            var comments = this.strip_tags(corrections[i].comments);


            cor.push({
                timeline_id: timelineid,
                corrected_time: time,
                correction_date: date,
                comments: comments

            })


        }


        console.log(cor);


        axios.post(API_ROOT + '/corrections', {
            emp_id: localStorage.getItem("emp_id"),
            emp_token: localStorage.getItem("emp_token"),
            merchant_id: localStorage.getItem("merchant_id"),
            corrections: JSON.stringify(cor)

        })
            .then(response => {

                this.onCloseModal();

                if (response.data.status == 1) {

                    this.handleCloseTimeRequestModalDropdown();

                    this.state.swalButton.fire({
                        title: "Your time correction has been submitted!",
                        text: 'Your request will be reviewed and any necessary corrections will be made.',
                        allowOutsideClick: true
                    });


                } else {
                    this.state.swalButton.fire({
                        title: response.data.message,
                        allowOutsideClick: false
                    });

                }
            }).catch(error => {
            this.onCloseModal();
            console.log(error);
        });

    }

    submitCorrection = () => {
        var editHourTimeline = this.state.editHourTimeline;
        var validationFlag = true;
        var errIndex;
        var i;
        //var ClockInCount = 0, ClockOutCount = 0, LunchInCount = 0, LunchOutCount = 0;

        for (i = 0; i < editHourTimeline.length; i++) {

            //to check first element is always Shift Start
            if (i === 0 && editHourTimeline[i].timeline_event !== "I" && editHourTimeline[i].timeline_corr_time !== "") {
                editHourTimeline[i].timeline_show_validation = true;
                editHourTimeline[i].timeline_show_validation_dialog = true;
                editHourTimeline[i].timeline_show_validation_message = "F";
                this.setState({editHourTimeline: editHourTimeline});
                this.setState({showCorrValidation: true});
                this.setState({corrValidationMsg: "The first element must be Shift Start."});
                return null;
            }

            //sequence validation
            if (editHourTimeline[i + 1] && editHourTimeline[i + 1].timeline_corr_time !== "") {
                if (editHourTimeline[i].timeline_event === "I" && editHourTimeline[i + 1].timeline_event === "S") {
                    validationFlag = false;
                    break;
                } else if (editHourTimeline[i].timeline_event === "B" && editHourTimeline[i + 1].timeline_event !== "S") {
                    validationFlag = false;
                    break;
                } else if (editHourTimeline[i].timeline_event === "S" && editHourTimeline[i + 1].timeline_event === "I") {
                    validationFlag = false;
                    break;
                } else if (editHourTimeline[i].timeline_event === "O" && editHourTimeline[i + 1].timeline_event !== "I") {
                    validationFlag = false;
                    break;
                } else if (editHourTimeline[i].timeline_event === editHourTimeline[i + 1].timeline_event) {
                    validationFlag = false;
                    break;
                }
            } else {
                //to check last element is always Shift End
                console.log(moment(this.state.editHourDateSelected).format('YYYY-MM-DD') + " vs " + moment().format('YYYY-MM-DD'));
                if (editHourTimeline[i].timeline_event !== "O" && editHourTimeline[i].timeline_corr_time !== "" && moment(this.state.editHourDateSelected).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
                    editHourTimeline[i].timeline_show_validation = true;
                    editHourTimeline[i].timeline_show_validation_dialog = true;
                    editHourTimeline[i].timeline_show_validation_message = "L";
                    this.setState({editHourTimeline: editHourTimeline});
                    this.setState({showCorrValidation: true});
                    this.setState({corrValidationMsg: "The last element must be Shift End."});
                    return null;
                }
            }


            //symmetrical validation
            // if (editHourTimeline[i].timeline_event === "I" && editHourTimeline[i].timeline_corr_time !== "") {
            //     ClockInCount++;
            // } else if (editHourTimeline[i].timeline_event === "B" && editHourTimeline[i].timeline_corr_time !== "") {
            //     LunchInCount++;
            // } else if (editHourTimeline[i].timeline_event === "S" && editHourTimeline[i].timeline_corr_time !== "") {
            //     LunchOutCount++;
            // } else if (editHourTimeline[i].timeline_event === "O" && editHourTimeline[i].timeline_corr_time !== "") {
            //     ClockOutCount++;
            // }
        }

        //symmetrical validation
        // console.log(ClockInCount + " " + ClockOutCount + " " + LunchInCount + " " + LunchOutCount);
        // if (ClockInCount !== ClockOutCount) {
        //     if (ClockInCount > ClockOutCount) {
        //         this.state.swalButton.fire({
        //             title: "You may have inserted extra Shift Start, or Forgot to add Shift End. Please try again.",
        //             allowOutsideClick: false
        //         });
        //         return null;
        //     } else {
        //         this.state.swalButton.fire({
        //             title: "You may have inserted extra Shift End, or Forgot to add Shift Start. Please try again.",
        //             allowOutsideClick: false
        //         });
        //         return null;
        //     }
        // }
        //
        // if (LunchInCount !== LunchOutCount) {
        //     if (LunchInCount > LunchOutCount) {
        //         this.state.swalButton.fire({
        //             title: "You may have inserted extra Lunch Start, or Forgot to add Lunch End. Please try again.",
        //             allowOutsideClick: false
        //         });
        //         return null;
        //     } else {
        //         this.state.swalButton.fire({
        //             title: "You may have inserted extra Lunch End, or Forgot to add Lunch Start. Please try again.",
        //             allowOutsideClick: false
        //         });
        //         return null;
        //     }
        // }

        console.log("FOR LOOP EXIT WITH VALIDATION FLAG : " + validationFlag);

        if (validationFlag) {
            let comment = "", changeFlag = false;

            for (i = 0; i < editHourTimeline.length; i++) {
                if (editHourTimeline[i].timeline_time !== editHourTimeline[i].timeline_corr_time) {
                    editHourTimeline[i].timeline_update = "Y";
                    changeFlag = true;
                } else {
                    editHourTimeline[i].timeline_update = "N";
                }
            }

            comment = $('.correctionModalCommentBox').val();
            //check if comment is not null
            if (!changeFlag) {
                console.log("No corrections!!!");
                return null;
            } else if (comment.length === 0) {
                this.state.swalButton.fire({
                    title: "Please Add Comment!",
                    allowOutsideClick: false
                });
                return null;
            } else {
                console.log("SUBMITTING CORRECTION...");
                this.onOpenModal("Submitting Correction");

                var corr = [];

                for (var k = 0; k < editHourTimeline.length; k++) {
                    if (editHourTimeline[k].timeline_time != "" || editHourTimeline[k].timeline_corr_time != "") {
                        corr.push(editHourTimeline[k]);
                    }
                }

                console.log("SUBMITTED CORRECTION ARRAY : ");
                console.info(editHourTimeline);
                console.info(corr);
                console.log("SUBMITTED CORRECTION COMMENT : " + comment);

                axios.post(API_ROOT + '/corrections', {
                    emp_id: localStorage.getItem("emp_id"),
                    emp_token: localStorage.getItem("emp_token"),
                    merchant_id: localStorage.getItem("merchant_id"),
                    corrections: JSON.stringify(corr),
                    correction_note: this.strip_tags(comment),
                    correction_date: moment(this.state.editHourDateSelected).format("YYYY-MM-DD")
                })
                    .then(response => {

                        this.onCloseModal();

                        if (response.data.status == 1) {

                            this.handleCloseTimeRequestModalEditHour();
                            this.setState({reports_to: response.data.emp_reports_to});
                            this.setState({showSubmitAlert: true});


                        } else {
                            this.state.swalButton.fire({
                                title: response.data.message,
                                allowOutsideClick: false
                            });

                        }
                    }).catch(error => {
                    this.onCloseModal();
                    console.log(error);
                });
            }

        } else {
            //Throwing Error Popper

            //Getting Event Name of Previous Event
            var message = this.state.editHourTimeline[i].timeline_event;
            if (message === "I") {
                message = "Start Shift"
            } else if (message === "O") {
                message = "End Shift"
            } else if (message === "B") {
                message = "Start Lunch"
            } else if (message === "S") {
                message = "End Lunch"
            }

            //Throwing Error Popper On Next Item:-
            errIndex = i + 1;
            editHourTimeline[errIndex].timeline_show_validation = true;
            editHourTimeline[errIndex].timeline_show_validation_dialog = true;
            editHourTimeline[errIndex].timeline_show_validation_message = message;
            this.setState({editHourTimeline: editHourTimeline});
            this.setState({showCorrValidation: true});
            this.setState({corrValidationMsg: message + " cannot be after " + this.getEventName(editHourTimeline[errIndex].timeline_event) + "."});
            this.sortEditHourTimeline();
        }
    }

    getEventName = (eventCode) => {

        if (eventCode === "I") {
            eventCode = "Start Shift"
        } else if (eventCode === "O") {
            eventCode = "End Shift"
        } else if (eventCode === "B") {
            eventCode = "Start Lunch"
        } else if (eventCode === "S") {
            eventCode = "End Lunch"
        }

        return eventCode;
    }

    addCorrection = e => {

        var corrections = this.state.corrections;


        corrections.push({
            correction_date: "",
            timeline_id: "",
            corrected_time: "",
            comments: "",
            timelines: []
        })

        this.setState({corrections: corrections});

    };

    correctedTime = (e, index) => {

        var corrections = this.state.corrections;


        corrections[index].timeline_id = e.target.value;

        this.setState({corrections: corrections});


    }
    onChangeComments = (e, index) => {
        var corrections = this.state.corrections;


        corrections[index].comments = e.target.value;

        this.setState({corrections: corrections});
    }

    onUpdate = ({translate}) => {
        console.log(`onUpdate: translate: ${translate}`);
        this.setState({translate});
    };

    onSelect = key => {


        this.setState({preSelectedDate: moment(key).format("YYYY-MM-DD")});
        this.fetchTimeline(moment(key).format("YYYY-MM-DD"), 0, "T");

        this.setState({selected: key});
    };

    setCurrentView = (currentView) => {

        this.setState({currentView: currentView});


        var count = 0;
        var list = this.state.payPeriodList;
        var sub = [];
        var foundFlag = 0;
        var main = [];
        var currentPayWeek = 0;
        var currentDate = this.state.preSelectedDate;

        if (currentDate == "") {
            currentDate = moment().format("YYYY-MM-DD");
        }

        if (currentView == "D") {

            while (count < list.length) {

                sub.push(list[count]);


                if (list[count].dt == currentDate) {
                    foundFlag = 1;

                }
                count++;


                if (count % 7 == 0) {

                    if (foundFlag == 1) {
                        this.setState({currentPayWeek: main.length});
                        currentPayWeek = main.length;
                    }
                    main.push(sub);
                    sub = [];
                    foundFlag = 0;
                }

            }


            if (sub.length > 0) {
                main.push(sub);
            }

            console.log(main);


            if (currentPayWeek == 0) {

                this.setState({showPrevious: false});
            } else {
                this.setState({showPrevious: true});
            }

            if (currentPayWeek == main.length - 1) {

                this.setState({showNext: false});
            } else {
                this.setState({showNext: true});
            }
        } else {

            while (count < list.length) {

                sub.push(list[count]);


                if (list[count].dt == currentDate) {
                    foundFlag = 1;

                }
                count++;


                if (count % 3 == 0) {

                    if (foundFlag == 1) {
                        this.setState({currentPayWeek: main.length});
                        currentPayWeek = main.length;
                    }
                    main.push(sub);
                    sub = [];
                    foundFlag = 0;
                }

            }


            if (sub.length > 0) {
                main.push(sub);
            }

            console.log("MAIN");
            console.log(main);


            if (currentPayWeek == 0) {

                this.setState({showPrevious: false});
            } else {
                this.setState({showPrevious: true});
            }

            console.log("CURRENT " + currentPayWeek + " MAIN: " + main.length);
            if (currentPayWeek == main.length - 1) {

                this.setState({showNext: false});
            } else {
                this.setState({showNext: true});
            }
        }
    }


    toggleTimesheet = () => {
        var timesheetflag = this.state.timesheetflag;

        /* if (this.state.preSelectedDate != "") {

         } else {*/
        this.fetchTimeline(this.state.preSelectedDate, 0, "T");
        // }
        this.setState({timesheetflag: !timesheetflag});
    }
    toProperCase = (txt) => {

        if (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        } else {
            return "";
        }
    }

    dropdownClicked = () => {
        $('#dropdown').toggleClass('active');
        $('#correctionModalDropdownButton').toggleClass('caret-up', 'caret-down');
    }

    editHourAccordianClicked = () => {
        //$('#EditHourAccordianHeader').toggleClass('caret-down','caret-up');
        if ($('#EditHourAccordianHeader').hasClass("caret-up")) {
            $('#EditHourAccordianHeader').removeClass("caret-up");
            $('#EditHourAccordianHeader').addClass("caret-down");
        } else if ($('#EditHourAccordianHeader').hasClass("caret-down")) {
            $('#EditHourAccordianHeader').removeClass("caret-down");
            $('#EditHourAccordianHeader').addClass("caret-up");
        }
    }

    toggleOverlay = () => {
        this.setState({showOverlay: !this.state.showOverlay});
    }

    checkCorrectionsMade = () => {
        var editHourTimeline = this.state.editHourTimeline;
        var submitDisableFlag = true;
        for (var i = 0; i < editHourTimeline.length; i++) {
            if (editHourTimeline[i].timeline_corr_time !== editHourTimeline[i].timeline_time) {
                submitDisableFlag = false;
                break;
            }
        }
        return submitDisableFlag;
    }

    checkAddTimeSelected = () => {
        var nextDisableFlag = true;
        if ($("input[type='radio'][name='addTimeRadioButton']:checked").val()) {
            nextDisableFlag = false;
        }
        return nextDisableFlag;
    }

    /* PTO MODAL */

    ptoModalOpen = () => {

        this.setState({selectedPtoStartDate: moment().format('MM/DD/YYYY')});
        this.setState({selectedPtoEndDate: moment().format('MM/DD/YYYY')});
        this.setState({timeRequestModalDropdown: false});
        this.setState({showPtoValidation: false});
        this.setState({ptoValidationMsg: ""});
        this.setState({ptoModal: true});
        $('#ptoSubmitBtn').removeClass('opacity20');
        this.fetchPto();
    }

    handleClosePtoModal = () => {
        this.setState({ptoModal: false});
        this.setState({showPtoValidation: false});
    }

    handleBackPtoModal = () => {
        this.setState({ptoModal: false});
        this.setState({timeRequestModalDropdown: true});
    }

    togglePtoStartTimeDropdown = () => {

        $('#ptoEndDateBox').removeClass('active');
        $('.ptoEndDatePicker').removeClass('active');
        $('#ptoStartDateBox').removeClass('active');
        $('.ptoStartDatePicker').removeClass('active');
        $('.ptoEndTimePicker').removeClass('active');
        $('#ptoEndTimeBox').removeClass('active');

        $('#ptoStartTimeBox').toggleClass('active');
        $('.ptoStartTimePicker').toggleClass('active');

        if ($('.ptoStartTimePicker').hasClass('active')) {

            var parsed = moment($('#ptoStartTime').html(), 'hh:mma');
            var onlyTime = parsed.format('hh:mm');
            var onlyAmPm = parsed.format('a');

            let duplicateThis = this;

            setTimeout(function () {

                //window.$('.clockTimePickerSubTitle').html(status);
                window.$('.time_picker_pto_start').val(onlyTime);
                window.$("input[name=pto_start_ampm][value=" + onlyAmPm + "]").prop('checked', true);

                window.$('.time_picker_pto_start').clockTimePicker({
                    precision: 5,
                    minimum: '1:00',
                    maximum: '12:59'
                    // onAdjust: function (newVal, oldVal) {
                    //     console.log(newVal + " " + oldVal);
                    // }
                });

                window.$('#ptoStartTimeOkBtn').click(function () {

                    var newTime = window.$('.time_picker_pto_start').val();
                    var newAmPm = window.$("input[name=pto_start_ampm]:checked").val();

                    const start_date_time = moment(newTime + newAmPm, "hh:mma");
                    const end_date_time = moment($('#ptoEndTime').html(), "hh:mma");

                    console.log("Time Obtained : " + newTime + newAmPm);
                    console.log("Start & End Time : " + start_date_time + "\n" + end_date_time);

                    if (end_date_time < start_date_time) {

                        duplicateThis.setState({ptoValidationMsg: "Start Time can't be after End Time."});
                        duplicateThis.setState({showPtoValidation: true});
                        $('.ptoStartTimePicker').removeClass('active');

                    } else {

                        duplicateThis.setState({ptoValidationMsg: ""});
                        duplicateThis.setState({showPtoValidation: false});
                        $('#ptoStartTime').html(newTime + newAmPm);
                        $('.ptoStartTimePicker').removeClass('active');

                        duplicateThis.updatePtoDuration();
                    }
                });

            }, 200);
        }
    }

    togglePtoEndTimeDropdown = () => {

        $('#ptoEndDateBox').removeClass('active');
        $('.ptoEndDatePicker').removeClass('active');
        $('#ptoStartDateBox').removeClass('active');
        $('.ptoStartDatePicker').removeClass('active');
        $('.ptoStartTimePicker').removeClass('active');
        $('#ptoStartTimeBox').removeClass('active');

        $('#ptoEndTimeBox').toggleClass('active');
        $('.ptoEndTimePicker').toggleClass('active');

        if ($('.ptoEndTimePicker').hasClass('active')) {

            var parsed = moment($('#ptoEndTime').html(), 'hh:mma');
            var onlyTime = parsed.format('hh:mm');
            var onlyAmPm = parsed.format('a');

            console.log($('#ptoEndTime').html() + " " + onlyTime + " " + onlyAmPm);
            let duplicateThis = this;

            setTimeout(function () {

                //window.$('.clockTimePickerSubTitle').html(status);
                window.$('.time_picker_pto_end').val(onlyTime);
                window.$("input[name=pto_end_ampm][value=" + onlyAmPm + "]").prop('checked', true);

                window.$('.time_picker_pto_end').clockTimePicker({
                    precision: 5,
                    minimum: '1:00',
                    maximum: '12:59'
                    // onAdjust: function (newVal, oldVal) {
                    //     console.log(newVal + " " + oldVal);
                    // }
                });

                window.$('#ptoEndTimeOkBtn').click(function () {

                    var newTime = window.$('.time_picker_pto_end').val();
                    var newAmPm = window.$("input[name=pto_end_ampm]:checked").val();

                    const start_date_time = moment($('#ptoStartTime').html(), "hh:mma");
                    const end_date_time = moment(newTime + newAmPm, "hh:mma");

                    console.log("Time Obtained : " + newTime + newAmPm);
                    console.log("Start & End Time : " + start_date_time + "\n" + end_date_time);

                    if (end_date_time < start_date_time) {

                        duplicateThis.setState({ptoValidationMsg: "End Time can't be before Start Time."});
                        duplicateThis.setState({showPtoValidation: true});
                        $('.ptoEndTimePicker').removeClass('active');

                    } else {

                        duplicateThis.setState({ptoValidationMsg: ""});
                        duplicateThis.setState({showPtoValidation: false});
                        $('#ptoEndTime').html(newTime + newAmPm);
                        $('.ptoEndTimePicker').removeClass('active');

                        duplicateThis.updatePtoDuration();
                    }
                });

            }, 200);
        }
    }

    togglePtoStartDateDropdown = () => {

        $('.ptoStartTimePicker').removeClass('active');
        $('#ptoStartTimeBox').removeClass('active');
        $('.ptoEndTimePicker').removeClass('active');
        $('#ptoEndTimeBox').removeClass('active');
        $('#ptoEndDateBox').removeClass('active');
        $('.ptoEndDatePicker').removeClass('active');

        $('#ptoStartDateBox').toggleClass('active');
        $('.ptoStartDatePicker').toggleClass('active');
    }

    onPtoStartDateSelected = (value, event) => {

        this.setState({selectedPtoStartDate: moment(value).format("MM/DD/yyyy")}, () => {

            this.setState({pto_from: moment(value).format("YYYY-MM-DD HH:mm:ss")}, () => {

                this.setState({selectedPtoEndDate: moment(value).format("MM/DD/yyyy")}, () => {

                    $('#ptoStartDate').html(this.state.selectedPtoStartDate);
                    $('#ptoEndDate').html(this.state.selectedPtoEndDate);

                    $('#ptoStartDateBox').removeClass('active');
                    $('.ptoStartDatePicker').removeClass('active');

                    this.fetchPto();
                    this.updatePtoDuration();
                });
            })

        });
    }

    togglePtoEndDateDropdown = () => {

        $('.ptoStartTimePicker').removeClass('active');
        $('#ptoStartTimeBox').removeClass('active');
        $('.ptoEndTimePicker').removeClass('active');
        $('#ptoEndTimeBox').removeClass('active');
        $('#ptoStartDateBox').removeClass('active');
        $('.ptoStartDatePicker').removeClass('active');

        $('#ptoEndDateBox').toggleClass('active');
        $('.ptoEndDatePicker').toggleClass('active');
    }

    onPtoEndDateSelected = (value, event) => {

        this.setState({selectedPtoEndDate: moment(value).format("MM/DD/yyyy")}, () => {

            $('#ptoEndDate').html(this.state.selectedPtoEndDate);

            $('#ptoEndDateBox').removeClass('active');
            $('.ptoEndDatePicker').removeClass('active');

            this.updatePtoDuration();
        });
    }

    updatePtoDuration = () => {

        const start_date = moment($('#ptoStartDate').html(), "MM/DD/YYYY");
        const end_date = moment($('#ptoEndDate').html(), "MM/DD/YYYY");
        const start_time = moment($('#ptoStartTime').html(), "hh:mma");
        const end_time = moment($('#ptoEndTime').html(), "hh:mma");

        var hours = 0;

        var diffOfTime = end_time.diff(start_time, 'minutes');
        var diffOfDate = end_date.diff(start_date, 'days');

        hours = (diffOfTime / 60).toFixed(2) * (diffOfDate + 1);

        this.setState({ptoDuration: hours});

        console.log((diffOfTime / 60).toFixed(2) + " * " + (diffOfDate + 1));
    }

    submitPto = () => {

        if (this.state.ptoDuration < 0) {

            this.setState({ptoValidationMsg: "Requested hours can't be negative."});
            this.setState({showPtoValidation: true});
            return;
        }

        if (!this.state.ptoBorrowingFlag) {
            if (this.state.ptoDuration > this.state.ptoBalance) {

                this.setState({ptoValidationMsg: "Your request exceeds the total number of hours available."});
                this.setState({showPtoValidation: true});
                return;
            }
        }

        if (this.state.pendingPtoCount > 0) {

            this.setState({ptoValidationMsg: "Please make sure you have no current pending requests before submitting another one."});
            this.setState({showPtoValidation: true});
            return;
        }

        this.onOpenModal("Loading");

        axios.post(API_ROOT + '/pto', {
            emp_id: localStorage.getItem("emp_id"),
            emp_token: localStorage.getItem("emp_token"),
            merchant_id: localStorage.getItem("merchant_id"),
            pto_from: moment($('#ptoStartDate').html() + " " + $('#ptoStartTime').html(), "MM/DD/YYYY hh:mma").format("YYYY-MM-DD HH:mm:ss"),
            pto_to: moment($('#ptoEndDate').html() + " " + $('#ptoEndTime').html(), "MM/DD/YYYY hh:mma").format("YYYY-MM-DD HH:mm:ss"),
            pto_hours: this.state.ptoDuration,
            pto_available_hours: this.state.ptoBalance,
            pto_remaining_hours: this.state.ptoBalance - this.state.ptoDuration,
            pto_notes: $('#ptoComments').val()
        })
            .then(response => {

                this.onCloseModal();

                if (response.data.status == 1) {

                    this.setState({ptoModal: false});
                    this.setState({reports_to: response.data.emp_reports_to});
                    this.setState({showSubmitAlert: true});
                }
            })

            .catch(error => {
                this.onCloseModal();
                console.log(error);
            });
    }

    fetchPto = () => {

        this.onOpenModal("Loading");

        axios.post(API_ROOT + '/fetchpto', {
            emp_id: localStorage.getItem("emp_id"),
            emp_token: localStorage.getItem("emp_token"),
            merchant_id: localStorage.getItem("merchant_id"),
            pto_from: this.state.pto_from
        })
            .then(response => {

                this.onCloseModal();

                if (response.data.status == 1) {

                    this.setState({ptoBalance: response.data.pto_balance});
                    this.setState({pendingPtoCount: response.data.pending_pto_count});

                    if (response.data.pto_borrowing_enabled == "Y") {
                        this.setState({ptoBorrowingFlag: true});
                    } else {
                        this.setState({ptoBorrowingFlag: false});
                    }

                    console.log(this.state.ptoBalance);
                    console.log(this.state.pendingPtoCount);

                } else {

                    this.setState({showPtoValidation: true});
                    this.setState({ptoValidationMsg: response.data.message});
                    $('#ptoSubmitBtn').addClass('opacity20');
                }
            }).catch(error => {
            this.onCloseModal();
            console.log(error);
        });
    }

    /* Vacation Modal */

    vacationModalOpen = () => {

        this.setState({selectedVacationStartDate: moment().format('MM/DD/YYYY')});
        this.setState({selectedVacationEndDate: moment().format('MM/DD/YYYY')});
        this.setState({timeRequestModalDropdown: false});
        this.setState({showVacationValidation: false});
        this.setState({vacationValidationMsg: ""});
        this.setState({vacationModal: true});
        $('#vacationSubmitBtn').removeClass('opacity20');
        this.fetchVacation();
    }

    handleCloseVacationModal = () => {
        this.setState({vacationModal: false});
        this.setState({showVacationValidation: false});
    }

    handleBackVacationModal = () => {
        this.setState({vacationModal: false});
        this.setState({timeRequestModalDropdown: true});
    }

    toggleVacationStartTimeDropdown = () => {

        $('#vacationEndDateBox').removeClass('active');
        $('.vacationEndDatePicker').removeClass('active');
        $('#vacationStartDateBox').removeClass('active');
        $('.vacationStartDatePicker').removeClass('active');
        $('.vacationEndTimePicker').removeClass('active');
        $('#vacationEndTimeBox').removeClass('active');

        $('#vacationStartTimeBox').toggleClass('active');
        $('.vacationStartTimePicker').toggleClass('active');

        if ($('.vacationStartTimePicker').hasClass('active')) {

            var parsed = moment($('#vacationStartTime').html(), 'hh:mma');
            var onlyTime = parsed.format('hh:mm');
            var onlyAmPm = parsed.format('a');

            let duplicateThis = this;

            setTimeout(function () {

                //window.$('.clockTimePickerSubTitle').html(status);
                window.$('.time_picker_vacation_start').val(onlyTime);
                window.$("input[name=vacation_start_ampm][value=" + onlyAmPm + "]").prop('checked', true);

                window.$('.time_picker_vacation_start').clockTimePicker({
                    precision: 5,
                    minimum: '1:00',
                    maximum: '12:59'
                    // onAdjust: function (newVal, oldVal) {
                    //     console.log(newVal + " " + oldVal);
                    // }
                });

                window.$('#vacationStartTimeOkBtn').click(function () {

                    var newTime = window.$('.time_picker_vacation_start').val();
                    var newAmPm = window.$("input[name=vacation_start_ampm]:checked").val();

                    const start_date_time = moment(newTime + newAmPm, "hh:mma");
                    const end_date_time = moment($('#vacationEndTime').html(), "hh:mma");

                    console.log("Time Obtained : " + newTime + newAmPm);
                    console.log("Start & End Time : " + start_date_time + "\n" + end_date_time);

                    if (end_date_time < start_date_time) {

                        duplicateThis.setState({vacationValidationMsg: "Start Time can't be after End Time."});
                        duplicateThis.setState({showVacationValidation: true});
                        $('.vacationStartTimePicker').removeClass('active');

                    } else {

                        duplicateThis.setState({vacationValidationMsg: ""});
                        duplicateThis.setState({showVacationValidation: false});
                        $('#vacationStartTime').html(newTime + newAmPm);
                        $('.vacationStartTimePicker').removeClass('active');

                        duplicateThis.updateVacationDuration();
                    }
                });

            }, 200);
        }
    }

    toggleVacationEndTimeDropdown = () => {

        $('#vacationEndDateBox').removeClass('active');
        $('.vacationEndDatePicker').removeClass('active');
        $('#vacationStartDateBox').removeClass('active');
        $('.vacationStartDatePicker').removeClass('active');
        $('.vacationStartTimePicker').removeClass('active');
        $('#vacationStartTimeBox').removeClass('active');

        $('#vacationEndTimeBox').toggleClass('active');
        $('.vacationEndTimePicker').toggleClass('active');

        if ($('.vacationEndTimePicker').hasClass('active')) {

            var parsed = moment($('#vacationEndTime').html(), 'hh:mma');
            var onlyTime = parsed.format('hh:mm');
            var onlyAmPm = parsed.format('a');

            console.log($('#vacationEndTime').html() + " " + onlyTime + " " + onlyAmPm);
            let duplicateThis = this;

            setTimeout(function () {

                //window.$('.clockTimePickerSubTitle').html(status);
                window.$('.time_picker_vacation_end').val(onlyTime);
                window.$("input[name=vacation_end_ampm][value=" + onlyAmPm + "]").prop('checked', true);

                window.$('.time_picker_vacation_end').clockTimePicker({
                    precision: 5,
                    minimum: '1:00',
                    maximum: '12:59'
                    // onAdjust: function (newVal, oldVal) {
                    //     console.log(newVal + " " + oldVal);
                    // }
                });

                window.$('#vacationEndTimeOkBtn').click(function () {

                    var newTime = window.$('.time_picker_vacation_end').val();
                    var newAmPm = window.$("input[name=vacation_end_ampm]:checked").val();

                    const start_date_time = moment($('#vacationStartTime').html(), "hh:mma");
                    const end_date_time = moment(newTime + newAmPm, "hh:mma");

                    console.log("Time Obtained : " + newTime + newAmPm);
                    console.log("Start & End Time : " + start_date_time + "\n" + end_date_time);

                    if (end_date_time < start_date_time) {

                        duplicateThis.setState({ptoValidationMsg: "End Time can't be before Start Time."});
                        duplicateThis.setState({showPtoValidation: true});
                        $('.vacationEndTimePicker').removeClass('active');

                    } else {

                        duplicateThis.setState({vacationValidationMsg: ""});
                        duplicateThis.setState({showVacationValidation: false});
                        $('#vacationEndTime').html(newTime + newAmPm);
                        $('.vacationEndTimePicker').removeClass('active');

                        duplicateThis.updateVacationDuration();
                    }
                });

            }, 200);
        }
    }

    toggleVacationStartDateDropdown = () => {

        $('.vacationStartTimePicker').removeClass('active');
        $('#vacationStartTimeBox').removeClass('active');
        $('.vacationEndTimePicker').removeClass('active');
        $('#vacationEndTimeBox').removeClass('active');
        $('#vacationEndDateBox').removeClass('active');
        $('.vacationEndDatePicker').removeClass('active');

        $('#vacationStartDateBox').toggleClass('active');
        $('.vacationStartDatePicker').toggleClass('active');
    }

    onVacationStartDateSelected = (value, event) => {

        this.setState({selectedVacationStartDate: moment(value).format("MM/DD/yyyy")}, () => {

            this.setState({selectedVacationEndDate: moment(value).format("MM/DD/yyyy")}, () => {

                $('#vacationStartDate').html(this.state.selectedVacationStartDate);
                $('#vacationEndDate').html(this.state.selectedVacationEndDate);

                $('#vacationStartDateBox').removeClass('active');
                $('.vacationStartDatePicker').removeClass('active');

                this.updateVacationDuration();
            });
        });
    }

    toggleVacationEndDateDropdown = () => {

        $('.vacationStartTimePicker').removeClass('active');
        $('#vacationStartTimeBox').removeClass('active');
        $('.vacationEndTimePicker').removeClass('active');
        $('#vacationEndTimeBox').removeClass('active');
        $('#vacationStartDateBox').removeClass('active');
        $('.vacationStartDatePicker').removeClass('active');

        $('#vacationEndDateBox').toggleClass('active');
        $('.vacationEndDatePicker').toggleClass('active');
    }

    onVacationEndDateSelected = (value, event) => {

        this.setState({selectedVacationEndDate: moment(value).format("MM/DD/yyyy")}, () => {

            $('#vacationEndDate').html(this.state.selectedVacationEndDate);

            $('#vacationEndDateBox').removeClass('active');
            $('.vacationEndDatePicker').removeClass('active');

            this.updateVacationDuration();
        });
    }

    fetchVacation = () => {

        this.onOpenModal("Loading");

        axios.post(API_ROOT + '/fetchvacation', {
            emp_id: localStorage.getItem("emp_id"),
            emp_token: localStorage.getItem("emp_token"),
            merchant_id: localStorage.getItem("merchant_id"),

        })
            .then(response => {

                this.onCloseModal();

                if (response.data.status == 1) {

                    this.setState({vacationBalance: response.data.vacation_balance});
                    this.setState({pendingVacationCount: response.data.pending_vacation_count});

                    console.log(this.state.vacationBalance);
                    console.log(this.state.pendingVacationCount);

                } else {

                    this.setState({showVacationValidation: true});
                    this.setState({vacationValidationMsg: response.data.message});
                    $('#vacationSubmitBtn').addClass('opacity20');
                }
            }).catch(error => {
            this.onCloseModal();
            console.log(error);
        });
    }

    updateVacationDuration = () => {

        const start_date = moment($('#vacationStartDate').html(), "MM/DD/YYYY");
        const end_date = moment($('#vacationEndDate').html(), "MM/DD/YYYY");
        const start_time = moment($('#vacationStartTime').html(), "hh:mma");
        const end_time = moment($('#vacationEndTime').html(), "hh:mma");

        var hours = 0;

        var diffOfTime = end_time.diff(start_time, 'minutes');
        var diffOfDate = end_date.diff(start_date, 'days');

        hours = (diffOfTime / 60).toFixed(2) * (diffOfDate + 1);

        this.setState({vacationDuration: hours});

        console.log((diffOfTime / 60).toFixed(2) + " * " + (diffOfDate + 1));
    }

    submitVacation = () => {

        if (this.state.vacationDuration < 0) {

            this.setState({vacationValidationMsg: "Requested hours can't be negative."});
            this.setState({showVacationValidation: true});
            return;
        }

        if (this.state.vacationDuration > this.state.vacationBalance) {

            this.setState({vacationValidationMsg: "Your request exceeds the total number of hours available."});
            this.setState({showVacationValidation: true});
            return;
        }

        if (this.state.pendingVacationCount > 0) {

            this.setState({vacationValidationMsg: "Please make sure you have no current pending requests before submitting another one."});
            this.setState({showVacationValidation: true});
            return;
        }

        this.onOpenModal("Loading");

        axios.post(API_ROOT + '/vacation', {
            emp_id: localStorage.getItem("emp_id"),
            emp_token: localStorage.getItem("emp_token"),
            merchant_id: localStorage.getItem("merchant_id"),
            vacation_from: moment($('#vacationStartDate').html() + " " + $('#vacationStartTime').html(), "MM/DD/YYYY hh:mma").format("YYYY-MM-DD HH:mm:ss"),
            vacation_to: moment($('#vacationEndDate').html() + " " + $('#vacationEndTime').html(), "MM/DD/YYYY hh:mma").format("YYYY-MM-DD HH:mm:ss"),
            vacation_hours: this.state.vacationDuration,
            vacation_available_hours: this.state.vacationBalance,
            vacation_remaining_hours: this.state.vacationBalance - this.state.vacationDuration,
            vacation_notes: $('#vacationComments').val()
        })
            .then(response => {

                this.onCloseModal();

                if (response.data.status == 1) {

                    this.setState({vacationModal: false});
                    this.setState({reports_to: response.data.emp_reports_to});
                    this.setState({showSubmitAlert: true});
                }
            })

            .catch(error => {
                this.onCloseModal();
                console.log(error);
            });
    }


    render() {

        const {open} = this.state;

        const {
            alignCenter,
            clickWhenDrag,
            hideArrows,
            dragging,
            hideSingleArrow,
            itemsCount,
            scrollToSelected,
            selected,
            translate,
            transition,
            wheel
        } = this.state;


        if (this.state.redirect404) {
            return <Redirect to="/404"/>;
        }
        if (this.state.redirect) {
            return <Redirect to="/login"/>;
        }

        return (<div id="mainBG" className="bg-login  w-100">
            <Helmet>
                <title>{"Time Clock - " + this.toProperCase(localStorage.getItem("merchant_subdomain"))}</title>

            </Helmet>


            <header>

                <IdleTimer/>

                <Topbar page="home" openCorrectionModal={this.openCorrectionModal}/>
            </header>
            <main className="w-100">
                <div className="time-clock-block time-clock w-100">
                    {/*<Link to="/home"> <img
                        src={IMAGE_ROOT + "/images/profiles/" + localStorage.getItem("merchant_image")}
                        className="logo" height="50" alt="Logo"/></Link>*/}

                    <div className="middle">
                        <div className="time-clock-content clearfix">
                            <div className="time-clockHeader">
                                <Link to="/home"><i className="icon-arrow-left"></i> </Link>

                                <p> Time Clock</p>
                                {/* <Link className="float-right" onClick={this.logout}>Logout</Link>*/}

                            </div>
                            <div className="time-clock-contentInner bg-grey float-left w-100">
                                <div className="clock-outBlock clock-outBlockFirst bg-white float-left w-100">
                                    <div className="clock-outBlockTop">
                                        <div className="float-left left clearfix">
                                            <p>
                                                {(this.state.todayMinutes == "0") ?
                                                    <span>  <i className="bi bi-clock bi-red"></i> &nbsp;
                                                        <div className="clockStatusContainer">
                                                                <div
                                                                    className="clockStatus">{this.state.currentDate}</div>
                                                                <div className="clockStatus">Not Clocked In</div>
                                                            </div>
                                                        </span> :

                                                    (this.state.currentStatus == "O") ?
                                                        <span>  <i className="bi bi-clock bi-red"></i> &nbsp;
                                                            <div className="clockStatusContainer">
                                                                <div
                                                                    className="clockStatus">{this.state.currentDate}</div>
                                                                <div
                                                                    className="clockStatus">Clocked Out at ({this.state.lastEventTime})</div>
                                                            </div>
                                                        </span> :

                                                        (this.state.currentStatus == "I" || this.state.currentStatus == "S") ?
                                                            <span>  <i className="bi bi-clock bi-green"></i> &nbsp;
                                                                <div className="clockStatusContainer">
                                                                <div
                                                                    className="clockStatus">{this.state.currentDate}</div>
                                                                <div
                                                                    className="clockStatus">Clocked In at ({this.state.lastEventTime})</div>
                                                            </div>
                                                        </span> :

                                                            (this.state.currentStatus == "B") ?
                                                                <span>  <i className="bi bi-clock bi-yellow"></i> &nbsp;
                                                                    <div className="clockStatusContainer">
                                                                <div
                                                                    className="clockStatus">{this.state.currentDate}</div>
                                                                <div
                                                                    className="clockStatus">Started Lunch at ({this.state.lastEventTime})</div>
                                                            </div>
                                                        </span> : ''
                                                }
                                            </p>
                                        </div>
                                        <div className="float-right right">
                                            <p> Projected Out: {this.state.projectedOut}</p>
                                        </div>
                                    </div>
                                    <div className="clock-outBlockmiddle">
                                        <p className="mb-2">
                                            <span>{this.state.currentTime}</span><sup>{this.state.currentTimeAMPM}</sup>
                                        </p>
                                        <p>Daily Hours {this.state.dailyHours}h {this.state.dailyMinutes}m</p>
                                        {/*<button onClick={() => this.getGeolocation()}>Check Geolocation</button>*/}
                                        <div className="clock-outmiddleLinks">

                                            {(this.state.currentStatus == "O") ?

                                                <a href="#" onClick={() => this.getGeolocation("I")}
                                                   className="bg-green float-left open-modal-clockinout">Clock In</a>

                                                :

                                                <a href="#" onClick={() => this.getGeolocation("O")}
                                                   className="bg-red float-left open-modal-clockinout">Clock Out</a>

                                            }


                                            {(this.state.currentStatus == "B") ?

                                                <a href="#" className="btn-yellow float-right"
                                                   onClick={() => this.getGeolocation("S")}>End Lunch Break</a>
                                                :

                                                <a href="#" className="btn-yellow float-right"
                                                   onClick={() => this.getGeolocation("B")}>Start Lunch Break</a>


                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="clock-outBlock bg-white float-left w-100 mb-0">
                                    <div className="clock-outBlockTop">
                                        <div className="float-left left clearfix">
                                            <p><span>Timecard</span></p>
                                        </div>
                                        <div className="float-right right">
                                            <p>{this.state.payPeriod}</p>
                                        </div>
                                    </div>
                                    <div className="clock-outBlockmiddle">
                                        <div className="timecard clearfix">
                                            <div className="timecardBlock float-left border-skyblue">
                                                <div className="timeCardMainLabel">Total Hours</div>
                                                <div className="timecardBlockInner">
                                                    <div className="timeClockHand">{this.state.weeklyHours}</div>
                                                    <div className="timeClockLabel" id="timeClockLabel">hr</div>
                                                    <div className="timeClockHand">{this.state.weeklyMinutes}</div>
                                                    <div className="timeClockLabel">min</div>
                                                </div>
                                            </div>
                                            <div className="timecardBlock float-right border-green">
                                                <div className="timeCardMainLabel">Projected Hours</div>
                                                <div className="timecardBlockInner">
                                                    <div className="timeClockHand">{this.state.projectedHours}</div>
                                                    <div className="timeClockLabel" id="timeClockLabel">hr</div>
                                                    <div className="timeClockHand">{this.state.projectedMinutes}</div>
                                                    <div className="timeClockLabel">min</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                                <div className="TimesheetDateSlider bg-white">
                                    <h3 onClick={this.toggleTimesheet}>Timesheet</h3>
                                    <p className="w-100" onClick={this.toggleTimesheet}>

                                        {!this.state.timesheetflag ?
                                            <i className="icon-angle-down"></i> :
                                            <i className="icon-angle-up"></i>}</p>
                                    {this.state.timesheetflag ? <div className="TimesheetDateSliderBlock">
                                        <div className="carousel-wrap">
                                            <ul className="owl-carousel donate-now">


                                                <Example payPeriodFrom={this.state.payPeriodFrom}
                                                         payPeriodTo={this.state.payPeriodTo}
                                                         preSelectedDate={this.state.preSelectedDate}
                                                         currentStep={this.state.currentPayWeek}
                                                         currentView={this.state.currentView}
                                                         callback={this.onDateSelected}
                                                         setCurrentView={this.setCurrentView}/>


                                                <div className="owl-nav">
                                                    {this.state.showPrevious ?
                                                        <div onClick={this.movePrevious} className="owl-prev"><i
                                                            className="icon-angle-left"></i></div> : ''}
                                                    {this.state.showNext ?
                                                        <div onClick={this.moveNext} className="owl-next"><i
                                                            className="icon-angle-right"></i></div> : ''}
                                                </div>

                                            </ul>
                                        </div>
                                    </div> : ''}
                                </div>

                                {this.state.timesheetflag ? <div className="clearfix"></div> : ''}
                                {this.state.timesheetflag ?

                                    <div className="DayShift">
                                        <div className="DayShiftDate">
                                            <p className="p-Dark  text-center">{moment(this.state.preSelectedDate).format("MMMM DD, YYYY")}</p>
                                        </div>
                                        <div className="DayShiftInner">

                                            <div id="style-3" className="timeSheetScroll">

                                                {this.state.timeline.map((item, index) => {

                                                    var status = "";

                                                    var time = item.timeline_value;

                                                    if (item.timeline_event == "I") {
                                                        status = "Shift Start"


                                                        return (
                                                            <div className="DayShiftBlock ShiftStart">
                                                                <div className="DayShiftBlockInner">
                                                                    <p>{status}</p>
                                                                    <p className="p-Dark">{time}</p>
                                                                </div>
                                                                <p className="mb-0"></p>
                                                            </div>);
                                                    } else if (item.timeline_event == "O") {
                                                        status = "Shift End"

                                                        return (
                                                            <div className="DayShiftBlock ShiftEnd">
                                                                <div className="DayShiftBlockInner">
                                                                    <p>{status}</p>
                                                                    <p className="p-Dark">{time}</p>
                                                                </div>
                                                            </div>);
                                                    } else if (item.timeline_event == "B") {
                                                        status = "Lunch Break Start"

                                                        return (
                                                            <div className="DayShiftBlock BreakStart">
                                                                <div className="DayShiftBlockInner mb-0">
                                                                    <p>{status}</p>
                                                                    <p className="p-Dark">{time}</p>
                                                                </div>
                                                            </div>);
                                                    } else if (item.timeline_event == "S") {
                                                        status = "Lunch Break End"

                                                        return (
                                                            <div className="DayShiftBlock BreakEnd">
                                                                <div className="DayShiftBlockInner">
                                                                    <p>{status}</p>
                                                                    <p className="p-Dark">{time}</p>
                                                                </div>
                                                            </div>);
                                                    }


                                                })}
                                            </div>


                                            <div className="clearfix"></div>
                                            <div className="total-hrs">
                                                <p className="p-Dark text-center mt-5">Total
                                                    Hours: {this.state.dateHours}</p>
                                            </div>
                                            <div className="clearfix"></div>
                                            <div className="timeshhetBtn clearfix text-center">
                                                {/* <a href="#" className="float-left">Timesheet Reports</a>*/}
                                                <Link to={"#"} onClick={this.openCorrectionModal}>Submit Time
                                                    Request</Link>
                                            </div>
                                        </div>
                                    </div> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </main>


            <BModal show={this.state.timeRequestModalDropdown} onHide={this.handleCloseTimeRequestModalDropdown}
                    dialogClassName="correctionModalSizeClass">

                <BModal.Body>

                    <div className="col-md-12">
                        <div className="row">
                            <div className="correctionModalHeaderWithButtons w-100">
                                <div></div>
                                Submit Time Request
                                <div className="correctionModalHeaderCloseBtn"
                                     onClick={this.handleCloseTimeRequestModalDropdown}><i className="bi bi-x"></i>
                                </div>
                            </div>
                        </div>
                        <div className="w-100">
                            <div className="modal-content border-0 p-0">
                                <div className="correctionModalBodyTitle">
                                    <p className="mx-3 my-4">Please tell us about the type of
                                        request you would like to submit. Once selected, fill in the required
                                        information.</p>
                                </div>

                                <div className="correctionModalDropdown text-center">
                                    <button id="correctionModalDropdownButton" className="caret-down"
                                            onClick={this.dropdownClicked}>What would you like to do?
                                    </button>
                                    <ul id="dropdown">
                                        <li onClick={this.timeCorrectionModalOpen}><a>Correct something on my current
                                            timesheet. </a>
                                        </li>
                                        {this.state.showPtoOption ? (
                                            <li onClick={this.ptoModalOpen}><a>Request paid time off.</a></li>
                                        ) : null}
                                        {this.state.showVacationOption ? (
                                            <li onClick={this.vacationModalOpen}><a>Request vacation.</a></li>
                                        ) : null}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </BModal.Body>

            </BModal>

            <BModal show={this.state.timeRequestModalTimeline} onHide={this.handleCloseTimeRequestModalTimeline}
                    dialogClassName="correctionModalSizeClass">

                <BModal.Body>

                    <div className="col-md-12 bg-fafafa">
                        <div className="row">
                            <div className="correctionModalHeaderWithButtons w-100">
                                <div className="correctionModalHeaderBackBtn" onClick={this.handleBackModalTimeline}><i
                                    className=" bi bi-arrow-left"></i>
                                </div>
                                Time Correction
                                <div className="correctionModalHeaderCloseBtn"
                                     onClick={this.handleCloseTimeRequestModalTimeline}><i className="bi bi-x"></i>
                                </div>
                            </div>
                        </div>
                        <div className="w-100">
                            <div className="modal-content border-0 p-0 bg-fafafa">
                                <div className="correctionModalBodyTitle2">
                                    <p className="mt-5 mb-2">Select a Shift to Modify</p>
                                </div>
                                <div className="correctionModalTimelines mb-3">
                                    <ul>
                                        {this.state.payPeriodListTillToday.map((item, index) => {
                                            return (
                                                <li onClick={() => this.onTimelineDateSelected(item.dt)}>{item.month} {item.date} - {item.day}</li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </BModal.Body>

            </BModal>

            <BModal show={this.state.timeRequestModalEditHour} onHide={this.handleCloseTimeRequestModalEditHour}
                    dialogClassName="correctionModalSizeClass">

                <BModal.Body>

                    <div className="col-md-12 p-0">
                        <div className="row m-0">
                            <div className="correctionModalHeaderWithButtons w-100">
                                <div className="correctionModalHeaderBackBtn" onClick={this.handleBackModalEditHour}><i
                                    className=" bi bi-arrow-left"></i>
                                </div>
                                Time Correction
                                <div className="correctionModalHeaderCloseBtn"
                                     onClick={this.handleCloseTimeRequestModalEditHour}><i className="bi bi-x"></i>
                                </div>
                            </div>
                        </div>
                        <div className="w-100">
                            <div className="modal-content border-0">
                                <div className="bg-fafafa">
                                    <div
                                        className={"mx-3 pto-vaca-warning " + (this.state.showCorrValidation ? 'd-block' : 'd-none')}
                                        role="alert">
                                        {this.state.corrValidationMsg}
                                    </div>
                                </div>
                                <div className="bg-fafafa px-2rem">
                                    <div className="correctionModalBodyTitle2">
                                        <p className="mt-3 mb-2">Edit Hours</p>
                                    </div>

                                    {/*Accordion card*/}
                                    <div className="card" id="EditHourAccordian">

                                        {/*Card header*/}
                                        <a className="card-header caret-up" data-toggle="collapse"
                                           href="#EditHourAccordianCollapse"
                                           aria-expanded="true" aria-controls="EditHourAccordianCollapse" role="tab"
                                           id="EditHourAccordianHeader"
                                           onClick={this.editHourAccordianClicked}>
                                            <h5 className="mb-0">
                                                {moment(this.state.editHourDateSelected).format("MMM DD - dddd")}
                                            </h5>
                                        </a>

                                        {/*Card body*/}
                                        <div id="EditHourAccordianCollapse" className="collapse show" role="tabpanel"
                                             aria-labelledby="EditHourAccordianHeader">
                                            <div className="card-body p-0">
                                                <ul>
                                                    {
                                                        this.state.editHourTimeline.map((item, index) => {

                                                            var status = "";
                                                            var corTime, actualTime, showValidation,
                                                                showValidationDialog;

                                                            if (item.timeline_corr_time === "") {
                                                                corTime = "--:-- --";
                                                            } else {
                                                                corTime = moment(item.timeline_corr_time).format("hh:mma");
                                                            }

                                                            if (item.timeline_event === "I") {
                                                                status = "Start Shift"
                                                            } else if (item.timeline_event === "O") {
                                                                status = "End Shift"
                                                            } else if (item.timeline_event === "B") {
                                                                status = "Start Lunch"
                                                            } else if (item.timeline_event === "S") {
                                                                status = "End Lunch"
                                                            }

                                                            (item.timeline_show_validation_dialog === true) ? showValidationDialog = true : showValidationDialog = false;
                                                            (item.timeline_show_validation === true) ? showValidation = true : showValidation = false;

                                                            return (
                                                                <li>
                                                                    <OverlayTrigger
                                                                        show={false} //showValidationDialog
                                                                        trigger='click'
                                                                        key='right'
                                                                        placement='right'
                                                                        overlay={
                                                                            <div className="popperContainer">
                                                                                <span className="triangle">◀</span>
                                                                                <div className="popper">
                                                                                    <div className="popperHeader mb-2">
                                                                                        <div
                                                                                            className="popperErrorIcon">
                                                                                            <svg
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                width="31"
                                                                                                height="31"
                                                                                                fill="#D13024"
                                                                                                className="bi bi-x-circle"
                                                                                                viewBox="0 0 16 16">
                                                                                                <path
                                                                                                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                                                <path
                                                                                                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                                                                            </svg>
                                                                                        </div>
                                                                                        <div
                                                                                            className="popperTitle">Error!
                                                                                        </div>
                                                                                        <div className="popperCloseIcon"
                                                                                             onClick={() => this.closeErrorPopper(index)}>
                                                                                            <i className="bi bi-x"/>
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        /*
                                                                                        DOCUMENTATION
                                                                                        message empty - future time entered
                                                                                        message same - the time entered is present timeline
                                                                                        message L - the last element is not Shift End
                                                                                        message F - the first element is not Shift Start
                                                                                        else message event name - sequential error, message will contain name of previous event
                                                                                        */
                                                                                        (item.timeline_show_validation_message === "") ?
                                                                                            <p>Your <span
                                                                                                className="popperHighlight">{status}</span> cannot
                                                                                                be at
                                                                                                a future time. Please
                                                                                                adjust
                                                                                                the
                                                                                                time to
                                                                                                be at or prior
                                                                                                to <span
                                                                                                    className="popperHighlight">{moment().format('hh:mmA')}</span>.
                                                                                            </p>
                                                                                            : (item.timeline_show_validation_message === "same") ?
                                                                                                <p>Please choose a different
                                                                                                    time for <span
                                                                                                        className="popperHighlight">{status}</span>
                                                                                                </p>
                                                                                                : (item.timeline_show_validation_message === "L") ?
                                                                                                    <p>The last element must
                                                                                                        be <span
                                                                                                            className="popperHighlight">Shift End</span>
                                                                                                    </p>
                                                                                                    : (item.timeline_show_validation_message === "F") ?
                                                                                                        <p>The first element
                                                                                                            must
                                                                                                            be <span
                                                                                                                className="popperHighlight">Shift Start</span>
                                                                                                        </p>
                                                                                                        :
                                                                                                        <p><span
                                                                                                            className="popperHighlight">{status}</span> cannot
                                                                                                            be after <span
                                                                                                                className="popperHighlight">{item.timeline_show_validation_message}</span>!
                                                                                                        </p>
                                                                                    }

                                                                                    {/*<p>Your <span*/}
                                                                                    {/*    className="popperHighlight">{status}</span> cannot*/}
                                                                                    {/*    be at*/}
                                                                                    {/*    a future time. Please adjust the time to*/}
                                                                                    {/*    be at or prior*/}
                                                                                    {/*    to <span*/}
                                                                                    {/*        className="popperHighlight">{moment().format('hh:mmA')}</span>.*/}
                                                                                    {/*</p>*/}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={`editHourText ${item.timeline_time !== item.timeline_corr_time ? "poppinsmedium" : ""} ${showValidation ? "editHourTextError" : ""}`}>
                                                                            {status}
                                                                            <span
                                                                                className={`material-icons ${showValidation ? "showInfoIcon" : "hideInfoIcon"}`}
                                                                                onClick={() => this.openErrorPopper(index)}>info</span>
                                                                        </div>

                                                                    </OverlayTrigger>
                                                                    {/*<div className="editHourText">{status}</div>*/}
                                                                    <div
                                                                        className={`editHourTimeBox ${item.timeline_time !== item.timeline_corr_time ? "poppinsmedium" : ""} ${showValidation ? "editHourTimeBoxError" : ""}`}
                                                                        onClick={() => this.openTimeRequestModalEditTime(item.timeline_time, item.timeline_corr_time, status, index)}>{corTime}
                                                                        <div className="editHourPencilIcon"><img
                                                                            src="/img/ic_pencil_icon_24px.svg"/></div>
                                                                    </div>
                                                                    <div className="editHourCloseIcon"
                                                                         onClick={() => this.clearTimeModalEditHour(index)}>
                                                                        <i className="bi bi-x"/></div>
                                                                </li>);

                                                        })}
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                    {/*Accordion card */}

                                    <button className="EditHourAddNewBtn mb-2rem"
                                            onClick={this.openTimeRequestModalAddTime}>
                                        Add new <i className="bi bi-plus-lg"/></button>
                                </div>

                                <div className="bg-white px-2rem">
                                    <div className="correctionModalSummaryContainer mt-3 py-2">
                                        <div className="correctionModalBodyTitle2 mb-3">
                                            <p className="">Summary of Corrections</p>
                                        </div>
                                        <div className="my-2 correctionModalSummary">
                                            <ul>
                                                {this.state.editHourTimeline.map((item, index) => {

                                                    if (item.timeline_time !== item.timeline_corr_time) {
                                                        var status = "";
                                                        var corTime, actualTime;

                                                        if (item.timeline_time === "") {
                                                            actualTime = "--:-- --";
                                                        } else {
                                                            actualTime = moment(item.timeline_time).format("hh:mma");
                                                        }

                                                        if (item.timeline_corr_time === "") {
                                                            corTime = "--:-- --";
                                                        } else {
                                                            corTime = moment(item.timeline_corr_time).format("hh:mma");
                                                        }

                                                        if (item.timeline_event == "I") {
                                                            status = "Start Shift"
                                                        } else if (item.timeline_event == "O") {
                                                            status = "End Shift"
                                                        } else if (item.timeline_event == "B") {
                                                            status = "Start Lunch"
                                                        } else if (item.timeline_event == "S") {
                                                            status = "End Lunch"
                                                        }
                                                        return (
                                                            <li>
                                                                <h4>{status}</h4>
                                                                <p>{actualTime} <i
                                                                    className="bi bi-arrow-right"/> {corTime}
                                                                </p>
                                                            </li>);
                                                    }
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="correctionModalBodyTitle2">
                                        <p className="mt-5 mb-2">Comments</p>
                                    </div>
                                    <textarea className="correctionModalCommentBox mb-2" maxLength="400"/>
                                    <hr className="editHourFooterLine"/>
                                    <div className="editHourFooter mb-4">
                                        <div className="editHourCancelBtn"
                                             onClick={this.handleCloseTimeRequestModalEditHour}>Cancel
                                        </div>
                                        <div
                                            className={`editHourSubmitBtn ${this.checkCorrectionsMade() ? "opacity20" : ""}`}
                                            onClick={this.checkCorrectionsMade() ? void (0) : this.submitCorrection}
                                            //onClick={this.submitCorrection}
                                        >Submit
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </BModal.Body>

            </BModal>

            <BModal show={this.state.timeRequestModalEditTime} onHide={this.handleCloseTimeRequestModalEditTime}
                    className="timeRequestModalEditTime">

                <BModal.Body>

                    <div className="col-md-12 bg-fafafa">
                        <div className="w-100">
                            <div className="modal-content border-0 p-0 bg-fafafa">
                                <div className="EditTimeModalBody">
                                    <div className="shiftCard position-relative">
                                        <div className="clockTimePickerTitle">
                                            Set time for <span className="clockTimePickerSubTitle"/>:
                                        </div>
                                        <div className="clock-timepicker">
                                            <ul className="ampm">
                                                <li>
                                                    <input type="radio" id="am" name="ampm" value="am"/>
                                                    <label htmlFor="am">AM</label>
                                                </li>
                                                <li>
                                                    <input type="radio" id="pm" name="ampm" value="pm"/>
                                                    <label htmlFor="pm">PM</label>
                                                </li>
                                            </ul>
                                            <input className="time time_picker" type="text"/>
                                        </div>
                                        <div onClick={this.handleCloseTimeRequestModalEditTime}
                                             className="editTimeCancelBtn">Cancel
                                        </div>
                                        <div className="editTimeOkBtn">Done</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </BModal.Body>

            </BModal>

            <BModal show={this.state.timeRequestModalAddTime} onHide={this.handleCloseTimeRequestModalAddTime}
                    className="timeRequestModalAddTime">

                <BModal.Body>

                    <div className="col-md-12 bg-fafafa">
                        <div className="w-100">
                            <div className="modal-content border-0 p-0 bg-fafafa">
                                <div className="AddTimeModalBody">
                                    <div className="correctionModalBodyTitle2">
                                        <p className="mt-5 mb-2">Select a shift event:</p>
                                    </div>
                                    <div className="addTimeRadioButtonsBody mb-2">
                                        <input className="form-check-input addTimeRadioButton" type="radio"
                                               name="addTimeRadioButton"
                                               id="ShiftStartRadio" value="I"
                                               disabled={this.state.isShiftStartDisable}/>
                                        <label className="form-check-label addTimeRadioButtonLabel"
                                               htmlFor="ShiftStartRadio">
                                            <span className="customRadioButton"></span>
                                            Shift Start
                                        </label>

                                        <input className="form-check-input addTimeRadioButton" type="radio"
                                               name="addTimeRadioButton"
                                               id="ShiftEndRadio" value="O" disabled={this.state.isShiftEndDisable}/>
                                        <label className="form-check-label addTimeRadioButtonLabel"
                                               htmlFor="ShiftEndRadio">
                                            <span className="customRadioButton"></span>
                                            Shift End
                                        </label><br/>

                                        <input className="form-check-input addTimeRadioButton" type="radio"
                                               name="addTimeRadioButton"
                                               id="LunchStartRadio" value="B"
                                               disabled={this.state.isLunchStartDisable}/>
                                        <label className="form-check-label addTimeRadioButtonLabel"
                                               htmlFor="LunchStartRadio">
                                            <span className="customRadioButton"></span>
                                            Lunch Start
                                        </label>

                                        <input className="form-check-input addTimeRadioButton" type="radio"
                                               name="addTimeRadioButton"
                                               id="LunchEndRadio" value="S" disabled={this.state.isLunchEndDisable}/>
                                        <label className="form-check-label addTimeRadioButtonLabel"
                                               htmlFor="LunchEndRadio">
                                            <span className="customRadioButton"></span>
                                            Lunch End
                                        </label>
                                    </div>

                                    <div className="addTimeButtons mb-2">
                                        <div className="addTimeCancelBtn"
                                             onClick={this.handleCloseTimeRequestModalAddTime}>Cancel
                                        </div>
                                        <div
                                            className={`addTimeNextBtn ${this.checkAddTimeSelected() ? "opacity20" : ""}`}
                                            onClick={this.handleNextTimeRequestModalAddTime}>Next
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </BModal.Body>

            </BModal>

            <BModal show={this.state.ptoModal} onHide={this.handleClosePtoModal}
                    dialogClassName="correctionModalSizeClass">

                <BModal.Body>

                    <div className="col-md-12 p-0">
                        <div className="row m-0">
                            <div className="correctionModalHeaderWithButtons w-100">
                                <div className="correctionModalHeaderBackBtn" onClick={this.handleBackPtoModal}><i
                                    className=" bi bi-arrow-left"></i>
                                </div>
                                PTO Request
                                <div className="correctionModalHeaderCloseBtn"
                                     onClick={this.handleClosePtoModal}><i className="bi bi-x"></i>
                                </div>
                            </div>
                        </div>
                        <div className="w-100">
                            <div className="modal-content border-0">
                                <div
                                    className={"mx-3 pto-vaca-warning " + (this.state.showPtoValidation ? 'visibility-visible' : 'visibility-hidden')}
                                    role="alert">
                                    {this.state.ptoValidationMsg}
                                </div>
                                <div className="ptoModalTitle">{this.state.ptoBalance} Hours</div>
                                <div className="ptoModalSubTitleContainer">
                                    <div className="ptoModalSubTitle">available as of</div>
                                    <div className="ptoModalSubTitleBold">{this.state.selectedPtoStartDate}</div>
                                </div>
                                <div className="ptoModalInputContainer mx-3 mb-2">
                                    <div className="ptoModalLabel">Start</div>
                                    <div className="ptoModalDateTimePickerContainer">

                                        <div className="ptoModalDatePickerContainer">
                                            <div className="ptoModalDateTimePicker"
                                                 onClick={this.togglePtoStartDateDropdown} id="ptoStartDateBox">
                                                <div className="ptoModalInputText"
                                                     id="ptoStartDate">{moment().format("MM/DD/YYYY")}</div>
                                                <i className="bi bi-calendar-range"></i>
                                            </div>
                                            <div className="ptoStartDatePicker">
                                                <div className="shiftCard position-relative">
                                                    <Calendar
                                                        onClickDay={this.onPtoStartDateSelected}
                                                        showNeighboringMonth={false}
                                                        minDate={moment().toDate()}
                                                        maxDate={moment().add(365, 'd').toDate()}
                                                        calendarType="US"
                                                        minDetail="month"
                                                        value={moment(this.state.selectedPtoStartDate, 'MM/DD/YYYY').toDate()}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="ptoModalTimePickerContainer">
                                            <div className="ptoModalDateTimePicker"
                                                 onClick={this.togglePtoStartTimeDropdown} id="ptoStartTimeBox">
                                                <div className="ptoModalInputText" id="ptoStartTime">09:00am</div>
                                                <i className="bi bi-clock"></i>
                                            </div>
                                            <div className="ptoStartTimePicker">
                                                <div className="shiftCard position-relative">
                                                    <div className="clockTimePickerTitle">
                                                        Set <span className="clockTimePickerSubTitle">Start</span> time:
                                                    </div>
                                                    <div className="clock-timepicker">
                                                        <ul className="ampm">
                                                            <li>
                                                                <input type="radio" id="am" name="pto_start_ampm"
                                                                       value="am"/>
                                                                <label htmlFor="am">AM</label>
                                                            </li>
                                                            <li>
                                                                <input type="radio" id="pm" name="pto_start_ampm"
                                                                       value="pm"/>
                                                                <label htmlFor="pm">PM</label>
                                                            </li>
                                                        </ul>
                                                        <input className="time time_picker_pto_start" type="text"/>
                                                    </div>
                                                    <div onClick={this.togglePtoStartTimeDropdown}
                                                         className="editTimeCancelBtn">Cancel
                                                    </div>
                                                    <div className="editTimeOkBtn" id="ptoStartTimeOkBtn">Done</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="ptoModalInputContainer mx-3">
                                    <div className="ptoModalLabel">End</div>
                                    <div className="ptoModalDateTimePickerContainer">

                                        <div className="ptoModalDatePickerContainer">
                                            <div className="ptoModalDateTimePicker"
                                                 onClick={this.togglePtoEndDateDropdown} id="ptoEndDateBox">
                                                <div className="ptoModalInputText"
                                                     id="ptoEndDate">{moment().format("MM/DD/YYYY")}</div>
                                                <i className="bi bi-calendar-range"></i>
                                            </div>
                                            <div className="ptoEndDatePicker">
                                                <Calendar
                                                    onClickDay={this.onPtoEndDateSelected}
                                                    showNeighboringMonth={false}
                                                    minDate={moment(this.state.selectedPtoStartDate, 'MM/DD/YYYY').toDate()}
                                                    maxDate={moment().add(365, 'd').toDate()}
                                                    calendarType="US"
                                                    minDetail="month"
                                                    value={moment(this.state.selectedPtoEndDate, 'MM/DD/YYYY').toDate()}
                                                />
                                            </div>
                                        </div>

                                        <div className="ptoModalTimePickerContainer">
                                            <div className="ptoModalDateTimePicker"
                                                 onClick={this.togglePtoEndTimeDropdown} id="ptoEndTimeBox">
                                                <div className="ptoModalInputText" id="ptoEndTime">05:00pm</div>
                                                <i className="bi bi-clock"></i>
                                            </div>
                                            <div className="ptoEndTimePicker">
                                                <div className="shiftCard position-relative">
                                                    <div className="clockTimePickerTitle">
                                                        Set <span className="clockTimePickerSubTitle">End</span> time:
                                                    </div>
                                                    <div className="clock-timepicker">
                                                        <ul className="ampm">
                                                            <li>
                                                                <input type="radio" id="am1" name="pto_end_ampm"
                                                                       value="am"/>
                                                                <label htmlFor="am1">AM</label>
                                                            </li>
                                                            <li>
                                                                <input type="radio" id="pm1" name="pto_end_ampm"
                                                                       value="pm"/>
                                                                <label htmlFor="pm1">PM</label>
                                                            </li>
                                                        </ul>
                                                        <input className="time time_picker_pto_end" type="text"/>
                                                    </div>
                                                    <div onClick={this.togglePtoEndTimeDropdown}
                                                         className="editTimeCancelBtn">Cancel
                                                    </div>
                                                    <div className="editTimeOkBtn" id="ptoEndTimeOkBtn">Done</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <span className="totalRequestedHours mt-5">
                                    Total Requested Hours: {this.state.ptoDuration}
                                </span>
                                <div className="mx-3">
                                    <div className="correctionModalBodyTitle2">
                                        <p className="mt-5 mb-2">Comments</p>
                                    </div>
                                    <textarea className="correctionModalCommentBox mb-2" id="ptoComments"
                                              maxLength="400"/>
                                    <hr className="editHourFooterLine"/>
                                    <div className="editHourFooter mb-4">
                                        <div className="editHourCancelBtn"
                                             onClick={this.handleClosePtoModal}>Cancel
                                        </div>
                                        <div
                                            className={`editHourSubmitBtn`}
                                            onClick={() => this.submitPto()}
                                            id="ptoSubmitBtn"
                                        >Submit
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </BModal.Body>

            </BModal>

            <BModal show={this.state.vacationModal} onHide={this.handleCloseVacationModal}
                    dialogClassName="correctionModalSizeClass">

                <BModal.Body>

                    <div className="col-md-12 p-0">
                        <div className="row m-0">
                            <div className="correctionModalHeaderWithButtons w-100">
                                <div className="correctionModalHeaderBackBtn" onClick={this.handleBackVacationModal}><i
                                    className=" bi bi-arrow-left"></i>
                                </div>
                                Vacation Request
                                <div className="correctionModalHeaderCloseBtn"
                                     onClick={this.handleCloseVacationModal}><i className="bi bi-x"></i>
                                </div>
                            </div>
                        </div>
                        <div className="w-100">
                            <div className="modal-content border-0">
                                <div
                                    className={"mx-3 pto-vaca-warning " + (this.state.showVacationValidation ? 'visibility-visible' : 'visibility-hidden')}
                                    role="alert">
                                    {this.state.vacationValidationMsg}
                                </div>
                                <div className="vacationModalTitle">{this.state.vacationBalance} Hours</div>
                                <div className="vacationModalSubTitleContainer">
                                    <div className="vacationModalSubTitle">available as of</div>
                                    <div className="vacationModalSubTitleBold">{moment().format("MM/DD/YYYY")}</div>
                                </div>
                                <div className="vacationModalInputContainer mx-3 mb-2">

                                    <div className="vacationModalLabel"
                                         onClick={() => this.setState({showVacationValidation: true})}>Start
                                    </div>

                                    <div className="vacationModalDateTimePickerContainer">

                                        <div className="vacationModalDatePickerContainer">
                                            <div className="vacationModalDateTimePicker"
                                                 onClick={this.toggleVacationStartDateDropdown}
                                                 id="vacationStartDateBox">
                                                <div className="vacationModalInputText"
                                                     id="vacationStartDate">{moment().format("MM/DD/YYYY")}</div>
                                                <i className="bi bi-calendar-range"></i>
                                            </div>
                                            <div className="vacationStartDatePicker">
                                                <div className="shiftCard position-relative">
                                                    <Calendar
                                                        onClickDay={this.onVacationStartDateSelected}
                                                        showNeighboringMonth={false}
                                                        minDate={moment().toDate()}
                                                        maxDate={moment().add(365, 'd').toDate()}
                                                        calendarType="US"
                                                        minDetail="month"
                                                        value={moment(this.state.selectedVacationStartDate, 'MM/DD/YYYY').toDate()}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="vacationModalTimePickerContainer">
                                            <div className="vacationModalDateTimePicker"
                                                 onClick={this.toggleVacationStartTimeDropdown}
                                                 id="vacationStartTimeBox">
                                                <div className="vacationModalInputText" id="vacationStartTime">09:00am
                                                </div>
                                                <i className="bi bi-clock"></i>
                                            </div>
                                            <div className="vacationStartTimePicker">
                                                <div className="shiftCard position-relative">
                                                    <div className="clockTimePickerTitle">
                                                        Set <span className="clockTimePickerSubTitle">Start</span> time:
                                                    </div>
                                                    <div className="clock-timepicker">
                                                        <ul className="ampm">
                                                            <li>
                                                                <input type="radio" id="am" name="vacation_start_ampm"
                                                                       value="am"/>
                                                                <label htmlFor="am">AM</label>
                                                            </li>
                                                            <li>
                                                                <input type="radio" id="pm" name="vacation_start_ampm"
                                                                       value="pm"/>
                                                                <label htmlFor="pm">PM</label>
                                                            </li>
                                                        </ul>
                                                        <input className="time time_picker_vacation_start" type="text"/>
                                                    </div>
                                                    <div onClick={this.toggleVacationStartTimeDropdown}
                                                         className="editTimeCancelBtn">Cancel
                                                    </div>
                                                    <div className="editTimeOkBtn" id="vacationStartTimeOkBtn">Done
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="vacationModalInputContainer mx-3">
                                    <div className="vacationModalLabel">End</div>
                                    <div className="vacationModalDateTimePickerContainer">

                                        <div className="vacationModalDatePickerContainer">
                                            <div className="vacationModalDateTimePicker"
                                                 onClick={this.toggleVacationEndDateDropdown} id="vacationEndDateBox">
                                                <div className="vacationModalInputText"
                                                     id="vacationEndDate">{moment().format("MM/DD/YYYY")}</div>
                                                <i className="bi bi-calendar-range"></i>
                                            </div>
                                            <div className="vacationEndDatePicker">
                                                <Calendar
                                                    onClickDay={this.onVacationEndDateSelected}
                                                    showNeighboringMonth={false}
                                                    minDate={moment(this.state.selectedVacationStartDate, 'MM/DD/YYYY').toDate()}
                                                    maxDate={moment().add(365, 'd').toDate()}
                                                    calendarType="US"
                                                    minDetail="month"
                                                    value={moment(this.state.selectedVacationEndDate, 'MM/DD/YYYY').toDate()}
                                                />
                                            </div>
                                        </div>

                                        <div className="vacationModalTimePickerContainer">
                                            <div className="vacationModalDateTimePicker"
                                                 onClick={this.toggleVacationEndTimeDropdown} id="vacationEndTimeBox">
                                                <div className="vacationModalInputText" id="vacationEndTime">05:00pm
                                                </div>
                                                <i className="bi bi-clock"></i>
                                            </div>
                                            <div className="vacationEndTimePicker">
                                                <div className="shiftCard position-relative">
                                                    <div className="clockTimePickerTitle">
                                                        Set <span className="clockTimePickerSubTitle">End</span> time:
                                                    </div>
                                                    <div className="clock-timepicker">
                                                        <ul className="ampm">
                                                            <li>
                                                                <input type="radio" id="am1" name="vacation_end_ampm"
                                                                       value="am"/>
                                                                <label htmlFor="am1">AM</label>
                                                            </li>
                                                            <li>
                                                                <input type="radio" id="pm1" name="vacation_end_ampm"
                                                                       value="pm"/>
                                                                <label htmlFor="pm1">PM</label>
                                                            </li>
                                                        </ul>
                                                        <input className="time time_picker_vacation_end" type="text"/>
                                                    </div>
                                                    <div onClick={this.toggleVacationEndTimeDropdown}
                                                         className="editTimeCancelBtn">Cancel
                                                    </div>
                                                    <div className="editTimeOkBtn" id="vacationEndTimeOkBtn">Done</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <span className="totalRequestedHours mt-5">
                                    Total Requested Hours: {this.state.vacationDuration}
                                </span>
                                <div className="mx-3">
                                    <div className="correctionModalBodyTitle2">
                                        <p className="mt-5 mb-2">Comments</p>
                                    </div>
                                    <textarea className="correctionModalCommentBox mb-2" id="vacationComments"
                                              maxLength="400"/>
                                    <hr className="editHourFooterLine"/>
                                    <div className="editHourFooter mb-4">
                                        <div className="editHourCancelBtn"
                                             onClick={this.handleCloseVacationModal}>Cancel
                                        </div>
                                        <div
                                            className={`editHourSubmitBtn`}
                                            onClick={() => this.submitVacation()}
                                            id="vacationSubmitBtn"
                                        >Submit
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </BModal.Body>

            </BModal>

            <BModal centered show={this.state.showModalOutsideGeofence} onHide={this.closeModalOutsideGeofence}
                    className="showModalOutsideGeofence">

                <BModal.Body>

                    <div className="col-md-12">
                        <div className="modalOutsideGeofenceBody my-3">
                            <div className="btnCloseModal"
                                 onClick={this.closeModalOutsideGeofence}><i className="bi bi-x"></i>
                            </div>
                            <div className="modalOutsideGeofenceHead">
                                <h3>Outside of Geofence</h3>
                            </div>
                            <p className="mt-4">{this.state.modalOutsideGeofenceMsg}</p>
                            <div className="btnOkayOG my-4" onClick={this.closeModalOutsideGeofence}>OK</div>
                        </div>
                    </div>

                </BModal.Body>

            </BModal>

            <BModal className="alertModal1" centered show={this.state.showEventAlert} onHide={this.handleEventClose}>

                <BModal.Body className={this.state.eventAlertType}>

                    <div className="col-md-12">
                        <div className="row">
                            <div className="modalboxHeader w-100 p-0">
                                &nbsp;
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="pt-3 alertIcon"><i className="bi bi-clock"></i></div>
                            <div className="time-clock-contentInner float-left w-100 ajustDialogPadding">
                                <div className="clock-outBlock bg-white float-left w-100">
                                    <div className="clock-outBlockmiddle ajustDialogPadding">
                                        <p className="mb-2">
                                            <span>{this.state.eventTime}</span><sup>{this.state.eventTimeAMPM}</sup>
                                        </p>
                                        <p>{this.state.eventDate}</p>
                                        <hr/>
                                    </div>
                                </div>
                            </div>
                            <div className="w-100">
                                <div className="modal-content1 border-0 w-100">
                                    <div className="timesubmitBlock">
                                        <div className="title1">
                                            <p className="mb-3 text-center">{this.state.eventStatus}</p>
                                        </div>
                                        <a href="#" onClick={this.handleEventClose}
                                           className="alertBtn w-100 mb-2 submit-btn">Ok</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </BModal.Body>

            </BModal>

            <BModal className="alertModal1" centered show={this.state.showAlert} onHide={this.handleEventClose}>

                <BModal.Body className="alertModal">

                    <div className="col-md-12">
                        <div className="row">
                            <div className="modalboxHeader w-100 p-0">
                                &nbsp;
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="pt-2 mt-4 alertIcon iconRound"><i className="bi bi-exclamation-lg"></i>
                            </div>
                            <div className="time-clock-contentInner pl-0 pr-0 w-100">
                                <div className="clock-outBlock bg-white  w-100">
                                    <div className="clock-outBlockmiddle  pl-0 pb-0 pr-0  ">
                                        <div className="alertModal-text">{this.state.alertTitle}</div>
                                        {(this.state.alertDesc != "") ?
                                            <div className="alertModal-desc pt-2">{this.state.alertDesc}
                                            </div> : ""}
                                    </div>
                                </div>
                            </div>
                            <div className="w-100">
                                <div className="modal-content1 border-0 w-100">
                                    <div className="timesubmitBlock">
                                        <a href="#" onClick={this.handleEventClose}
                                           className="alertBtn w-100 mb-2 submit-btn">Ok</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </BModal.Body>

            </BModal>

            <BModal className="alertModal1" centered show={this.state.showSubmitAlert} onHide={this.handleSubmitClose}
                    size="lg">

                <BModal.Body className="successModal">

                    <div className="col-md-12">
                        <div className="row">
                            <div className="modalboxHeader w-100 p-0">
                                &nbsp;
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="pt-3 alertIcon"><span
                                className="material-icons alertIcon">check_circle_outline</span></div>
                            <div className="time-clock-contentInner pl-0 pr-0 w-100">
                                <div className="clock-outBlock bg-white  w-100">
                                    <div className="clock-outBlockmiddle  pl-0 pb-0 pr-0  ">
                                        <div className="alertModal-text">Success!</div>
                                        <div className="alertModal-desc">Your time request has been sent to <span
                                            className="editHourHighlightText">{this.state.reports_to}</span>. Your
                                            request will be reviewed and any necessary changes will be made.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-100">
                                <div className="modal-content1 border-0 w-100">
                                    <div className="timesubmitBlock">
                                        <a href="#" onClick={this.handleSubmitClose}
                                           className="alertBtn w-100 mb-2 submit-btn">Ok</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </BModal.Body>

            </BModal>

            <Modal closeOnEsc={false} closeOnOverlayClick={false} showCloseIcon={false} open={open}
                   onClose={this.onCloseModal} center dialogClassName="loadingModal" backdrop="static"
                   keyboard={false}>
                <h2>&nbsp;&nbsp;&nbsp;&nbsp;{this.state.loading}...&nbsp;&nbsp;&nbsp;&nbsp;</h2>

                <p style={{textAlign: 'center'}}>
                    <img width={'40'} height={'40'} alt="Loading..." src={'/img/loading1.gif'}/>
                </p>
            </Modal>
        </div>);
    }

}

export default withRouter(home);
