import React, {Component} from 'react';
import {withRouter} from "react-router-dom";

class notfound404 extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {};
    };


    render() {


        return (<div >
                <div className="clearfix"></div>
                <div className="page" style={{background: "#F8F9FC"}}>
                    <div className="container"  style={{height: "100vh"}}>
                        <div className="text-center" style={{top:"30%",position:"relative"}}>
                            <div className="error mx-auto" data-text="404">404</div>
                            <p className="lead text-gray-800 mb-3">Page Not Found</p>
                            <p className="text-gray-500 mb-0">Sorry the page you are looking for could not be found, please<br/> contact the webmaster at <a href="mailto:admin@bryteps.com"> admin@bryteps.com</a></p>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(notfound404);