import React, {Component} from 'react';
import {Redirect, withRouter} from 'react-router-dom'
import {API_ROOT, DOMAIN, IMAGE_ROOT} from "../api-config";
import 'react-responsive-modal/styles.css';
import Modal from "react-responsive-modal";
import axios from "axios";
import $ from "jquery";
import Swal from "sweetalert2";
import {Helmet} from "react-helmet";

class forgot extends Component {

    constructor(props, context) {
        super(props, context);
        console.log(DOMAIN);

        this.state = {
            redirect404: false,
            redirecthome: false,
            redirectlogin: false,
            swalButton: "",
            message: "",
            success: false,
            open: false,
            merchant_image: "",

        };

    };

    componentWillMount() {


        if (!localStorage.getItem("emp_id")) {

            sessionStorage.clear();

        } else {

            this.setState({redirect: true});
        }

        this.setState({
            swalButton: Swal.mixin({
                customClass: {
                    confirmButton: 'btn-yellow',
                    cancelButton: 'btn-yellow'
                },
                buttonsStyling: false,
            })
        });

    }

    componentDidMount() {

        $("title").html('Employee Forgot Password');
        $("body").addClass("login-page");
        this.fetchMerchant();

    }

    fetchMerchant = () => {

        var url = API_ROOT + '/fetchmerchant';

        this.setState({loading: true});
        fetch(url)
            .then(response => {
                return response.json();
            })
            .then(merchantData => {

                if (merchantData.status == 1 && merchantData.merchant_data.length > 0) {


                    localStorage.setItem('merchant_id', merchantData.merchant_data[0].merchant_id);
                    localStorage.setItem('merchant_name', merchantData.merchant_data[0].merchant_name);
                    localStorage.setItem('merchant_image', merchantData.merchant_data[0].merchant_image);
                    localStorage.setItem('merchant_subdomain', merchantData.merchant_data[0].merchant_subdomain);

                    localStorage.setItem("merchant_front_login_bg", merchantData.merchant_data[0].merchant_front_login_bg);
                    localStorage.setItem("merchant_front_dashboard_bg", merchantData.merchant_data[0].merchant_front_dashboard_bg);
                    localStorage.setItem("merchant_front_timeclock_bg", merchantData.merchant_data[0].merchant_front_timeclock_bg);


                    this.loadProperties();


                    this.setState({merchant_image: merchantData.merchant_data[0].merchant_image});


                } else {

                    this.setState({redirect: false});
                    this.setState({redirect404: true});
                }
            });
    };

    loadProperties = () => {
        var frontLoginBG = localStorage.getItem("merchant_front_login_bg");

        if (frontLoginBG != "") {
            document.documentElement.style.setProperty('--loginBackground', "linear-gradient(0deg, rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)), url(" + IMAGE_ROOT + "/images/profiles/" + frontLoginBG + ")");
        } else {
            document.documentElement.style.setProperty('--loginBackground', "linear-gradient(0deg, rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)), url(../img/login-banner.png)");
        }

        var menuBG = localStorage.getItem("merchant_front_dashboard_bg");

        if (menuBG != "") {
            document.documentElement.style.setProperty('--menuBackground', 'url("' + IMAGE_ROOT + '/images/profiles/' + menuBG + '")');
        } else {
            document.documentElement.style.setProperty('--menuBackground', 'url("../img/announcementbanner.jpeg")');
        }

        var timeClockBG = localStorage.getItem("merchant_front_timeclock_bg");

        if (timeClockBG != "") {
            document.documentElement.style.setProperty('--timeClockBackground', ' url("' + IMAGE_ROOT + '/images/profiles/' + timeClockBG + '") 0 0 no-repeat');
        } else {
            document.documentElement.style.setProperty('--timeClockBackground', ' url("../img/time-clock-banner.jpg") 0 0 no-repeat');
        }
    }

    onOpenModal = () => {
        this.setState({open: true});

    };
    onCloseModal = () => {
        this.setState({open: false});
    };

    login = e => {
        this.setState({redirectlogin: true});
    }
    forgot = e => {


        var user_email = $('#user_username').val();


        var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
        if (user_email === "") {
            this.state.swalButton.fire({
                title: 'Please enter email.',
                allowOutsideClick: false
            });

            return;
        }


        this.onOpenModal();
        axios.post(API_ROOT + '/forgot/submit', {
            user_username: user_email,
            merchant_id: localStorage.getItem("merchant_id"),
            app: "Y"

        })
            .then(response => {

                this.onCloseModal();


                if (response.data.status == 1) {


                    this.setState({redirecthome: true});
                    this.setState({message: response.data.message});


                } else {

                    this.setState({message: response.data.message});

                }
            }).catch(error => {
            this.onCloseModal();
            console.log(error);
        });



    }

    toProperCase=(txt)=>{

        if(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }else{
            return "";
        }
    }

    render() {

        const {open} = this.state;
        if (this.state.redirect404) {
            return <Redirect to="/404"/>;
        }
        if (this.state.redirect) {
            return <Redirect to="/home"/>;
        }
        if (this.state.redirectlogin) {
            return <Redirect to="/login"/>;
        }


        return (<div className="bg-login">
            <Helmet>
                <title>{"Employee Forgot Password - " + this.toProperCase(localStorage.getItem("merchant_subdomain"))}</title>
            </Helmet>
            <main className="w-100">
                <div className="login-block">

                    {(this.state.merchant_image != "") ?
                        <img src={IMAGE_ROOT + "/images/profiles/" + this.state.merchant_image}
                             className="logo" alt="Logo Login"/> : ''}
                    <div className="login-content">
                        <h2>Reset My Password</h2>
                        {(!this.state.redirecthome) ? <div className="login-form">

                                {(this.state.message != "") ? <div className="alert alert-danger w-100">

                                    {this.state.message}
                                </div> : ''}
                                <div className="form-group mb-2">
                                    <input type="text" className="form-control" id="user_username"
                                           placeholder="Type Your Email Address"
                                    />


                                </div>


                                <div className="clearfix"></div>
                                <div className="form-group w-100">

                                    <button type="button" className="btn-yellow" onClick={this.forgot}>Send Link</button>

                                </div>
                            </div>
                            :
                            <div className="login-form">
                                <div className="alert alert-success w-100">

                                    {this.state.message}
                                </div>
                                <div className="clearfix"></div>

                                <div className="form-group w-100 mb-0">
                                    <button type="button" onClick={this.login} className="btn-yellow">Login</button>

                                </div>
                            </div>}
                        <div className="clearfix"></div>

                        <div className="clearfix"></div>
                        <div className="login-social-links">

                            <div className="clearfix"></div>
                            <p>Copyright &copy; 2021 Bryte All Rights Reserved</p>
                        </div>
                    </div>
                </div>
            </main>


            <Modal closeOnEsc={false} closeOnOverlayClick={false} showCloseIcon={false} open={open}
                   onClose={this.onCloseModal} center>
                <h2>&nbsp;&nbsp;&nbsp;&nbsp;Loading...&nbsp;&nbsp;&nbsp;&nbsp;</h2>

                <p style={{textAlign: 'center'}}>
                    <img width={'40'} height={'40'} alt="Loading..." src={'/img/loading1.gif'}/>
                </p>
            </Modal>

        </div>);
    }
}

export default withRouter(forgot);