import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";

class notfoundmerchant extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {};
    };


    render() {


        return (<div >
                <div className="clearfix"></div>
                <div className="page">
                    <div className="container bg-grey" style={{height:"100vh",margin:"0px",width:"100%",maxWidth:"100%"}}>
                        <h1 className="headingNotFound">OOps! That Page Does Not Exist</h1>
                        <h4 className="headingSubText">Sorry, the page you were looking for could not be found.</h4>
                        <h4 className="headingSubText">You can return home <a href="/">here</a></h4>
                        <div className="login_main" style={{textAlign: "right"}}>
                            <img className="logo404" alt="404" src="/img/image404.png"/>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(notfoundmerchant);