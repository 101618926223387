import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";

import {createBrowserHistory} from "history";


import Login from './pages/login';
import NotFound from "./pages/notfound404";
import NotFoundMerchant from "./pages/notfoundmerchant";
import Home from "./pages/home";
import Menu from "./pages/menu";
import Forgot from "./pages/forgot";
import TimeReport from "./pages/timereport";
import PrintReport from "./pages/printreport";


const history = createBrowserHistory();


ReactDOM.render(
    <Router history={history}>
        <Switch>


            <Route exact path="/404" component={NotFound}/>
            <Route exact path="/timeclock" component={Home}/>
            <Route exact path="/login" component={Login}/>
            <Route exact path="/home" component={Menu}/>
            <Route exact path="/forgot" component={Forgot}/>
            <Route exact path="/timereport" component={TimeReport}/>

            <Route exact path="/timereport/:type/:action/:id" component={PrintReport}/>

            <Route exact path="/" component={Login}/>
            <Route exact path="*" component={NotFoundMerchant}/>


        </Switch>
    </Router>

    , document.getElementById('root'));

export default history;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
