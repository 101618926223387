import React, {Component, useEffect, useState} from 'react';
import {Link, Redirect, withRouter} from 'react-router-dom'
import {API_ROOT, DOMAIN, IMAGE_ROOT} from "../api-config";
import 'react-responsive-modal/styles.css';
import Modal from "react-responsive-modal";
import axios from "axios";
import Swal from "sweetalert2";
import moment from 'moment-timezone';
import 'react-calendar/dist/Calendar.css';
import $ from "jquery";


import "react-datepicker/dist/react-datepicker.css";
import {Helmet} from "react-helmet";
import Topbar from "./topbar";
import IdleTimer from "./idletimer";

let list = [];


const useCheckMobileScreen = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (width <= 768);
}


class timereport extends Component {

    constructor(props, context) {
        super(props, context);
        console.log(DOMAIN);

        this.state = {
            redirect404: false,
            redirect: false,
            swalButton: "",
            open: false,
            show: false,
            payperiods: [],
            payperiodIndex: 0,
            loading: 'Loading',
            schedules: [],
            days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]


        };


    };

    componentDidUpdate(prevProps, prevState) {

    }


    componentWillMount() {

        if (!localStorage.getItem("emp_id")) {

            this.setState({redirect: true});
        }
        this.setState({
            swalButton: Swal.mixin({
                customClass: {
                    confirmButton: 'dialogButton',
                    cancelButton: 'dialogButton'
                },
                buttonsStyling: false,
            })
        });

    }


    componentDidMount() {

        $("body").removeClass("login-page");


        this.loadProperties();


        this.fetchPayPeriods();


    }


    toProperCase = (txt) => {

        if (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        } else {
            return "";
        }
    }
    loadProperties = () => {
        var frontLoginBG = localStorage.getItem("merchant_front_login_bg");

        if (frontLoginBG != "") {
            document.documentElement.style.setProperty('--loginBackground', "linear-gradient(0deg, rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)), url(" + IMAGE_ROOT + "/images/profiles/" + frontLoginBG + ")");
        } else {
            document.documentElement.style.setProperty('--loginBackground', "linear-gradient(0deg, rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)), url(../img/login-banner.png)");
        }

        var menuBG = localStorage.getItem("merchant_front_dashboard_bg");

        if (menuBG != "") {
            document.documentElement.style.setProperty('--menuBackground', 'url("' + IMAGE_ROOT + '/images/profiles/' + menuBG + '")');
        } else {
            document.documentElement.style.setProperty('--menuBackground', 'url("../img/announcementbanner.jpeg")');
        }

        var timeClockBG = localStorage.getItem("merchant_front_timeclock_bg");

        if (timeClockBG != "") {
            document.documentElement.style.setProperty('--timeClockBackground', ' url("' + IMAGE_ROOT + '/images/profiles/' + timeClockBG + '") 0 0 no-repeat');
        } else {
            document.documentElement.style.setProperty('--timeClockBackground', ' url("../img/time-clock-banner.jpg") 0 0 no-repeat');
        }
    }

    onOpenModal = (msg) => {

        this.setState({loading: msg});
        this.setState({open: true});

    };
    onCloseModal = () => {
        this.setState({open: false});
    };

    logout = e => {
        localStorage.clear();
        this.setState({redirect: true});
    }

    loadTimeline = () => {

        if ($("#payperiod_dropdown").prop('selectedIndex') > -1) {
            this.setState({payperiodIndex: $("#payperiod_dropdown").prop('selectedIndex')});
        }


    }

    fetchPayPeriods = () => {


        this.onOpenModal("Loading");

        axios.post(API_ROOT + '/payperiods', {
            emp_id: localStorage.getItem("emp_id"),
            emp_token: localStorage.getItem("emp_token"),
            merchant_id: localStorage.getItem("merchant_id"),

        }).then(response => {

            this.onCloseModal();

            if (response.data.status == 1) {


                this.setState({payperiods: response.data.payperiods});


                if (response.data.data[0].schedule_id != "") {

                    for (var i = 0; i < response.data.schedules.length; i++) {

                        if (response.data.schedules[i].schedule_id == response.data.data[0].schedule_id) {
                            this.setState({schedules: response.data.schedules[i]});

                            break;
                        }
                    }
                }

                console.log(this.state.schedules);

            } else {
                this.state.swalButton.fire({
                    title: response.data.message,
                    allowOutsideClick: false
                });

            }
        }).catch(error => {
            this.onCloseModal();
            console.log(error);
        });


    }


    render() {

        const {open} = this.state;
        var hours = 0;

        var scheduleTotal = 0;
        if (this.state.redirect404) {
            return <Redirect to="/404"/>;
        }
        if (this.state.redirect) {
            return <Redirect to="/login"/>;
        }

        return (<div id="mainBG" className="bg-login  w-100">
            <Helmet>
                <title>{"Time Clock - " + this.toProperCase(localStorage.getItem("merchant_subdomain"))}</title>

            </Helmet>


            <header>

                <IdleTimer/>
                <Topbar page="report"/>
            </header>
            <main className="w-100">
                <div className="time-clock-block  bg-repeat time-clock w-100">
                    <div className="middle">
                        <div className="time-clock-content clearfix">
                            <div className="time-clockHeader">
                                <Link to="/home"><i className="icon-arrow-left"></i> </Link>

                                <p> Time Report</p>
                                {/* <Link className="float-right" onClick={this.logout}>Logout</Link>*/}

                            </div>
                            <div className="time-clock-contentInner text-center bg-white float-left w-100">


                                <div
                                    className="title_r mt-2 payPeriodDropdown d-inline-block datepicker-block m-auto position-relative">
                                    <form>
                                        <label className="float-left mr-2 mb-1">Pay Period</label>
                                        <div className=" payPeriodWidth float-left">
                                            <select id="payperiod_dropdown" onChange={this.loadTimeline}>

                                                {this.state.payperiods.map((item, index) => {

                                                    if (index > 0) {

                                                        return (<option
                                                            value={item.payperiod_id}>{item.payperiod_from1 + " - " + item.payperiod_to1}</option>);
                                                    }
                                                })}

                                            </select>


                                        </div>
                                    </form>
                                </div>

                                <div className="float-right mt-2 ">

                                    <a href={"/timereport/timesheet/download/" + this.state.payperiodIndex}
                                       target="_blank" class="mt-1 no-hover"><span
                                        className="material-icons">
download
</span> </a>
                                    <a href={"/timereport/timesheet/print/" + this.state.payperiodIndex} target="_blank"
                                       class="ml-2 no-hover"><span
                                        className="material-icons">
print
</span> </a>


                                </div>


                                {(this.state.payperiods[this.state.payperiodIndex + 1] && this.state.payperiods[this.state.payperiodIndex + 1].weeks && this.state.payperiods[this.state.payperiodIndex + 1].weeks.length > 0) ?

                                    this.state.payperiods[this.state.payperiodIndex + 1].weeks.map((week, index) => {

                                        var weekMinutes = 0;
                                        return (<div class="timesheetBlock mt-2   mb-2">
                                            <a href="#" class="a_edit invisible d-none float-left disabled">Edit</a>
                                            <div class="float-left w-100 date-title my-2">
                                                <p class="text-center d-inline start-end editStatus"> {moment(week.week_from,
                                                    "YYYY-MM-DD").format("MMMM DD, YYYY") + " - " + moment(week.week_to, "YYYY-MM-DD").subtract(1, 'days').format("MMMM DD, YYYY")}
                                                </p>

                                                {(this.state.payperiods[this.state.payperiodIndex + 1].payperiod_status == "A") ?
                                                    <div
                                                        className="float-right d-inline approvedBtnWhite">APPROVED</div> :
                                                    <div
                                                        className="float-right d-inline approvedBtnWhite">UNAPPROVED</div>}
                                            </div>

                                            <div class="clearfix"></div>

                                            <ul class="mainBlock">

                                                {(week.timesheet.length > 0) ?

                                                    week.timesheet.map((timesheet, index) => {


                                                        var dat = moment(timesheet.date, "YYYY-MM-DD");


                                                        var timlineMinutes = 0;
                                                        for (var i = 0; i < timesheet.events.length; i++) {

                                                            if (timesheet.events[i + 1]) {
                                                                if (timesheet.events[i].timeline_event == "I" && timesheet.events[i + 1].timeline_event == "B" || timesheet.events[i].timeline_event == "S" && timesheet.events[i + 1].timeline_event == "B" || timesheet.events[i].timeline_event == "S" && timesheet.events[i + 1].timeline_event == "O" || timesheet.events[i].timeline_event && timesheet.events[i + 1].timeline_event == "O") {


                                                                    var start = moment(timesheet.events[i].timeline_value, 'hh:mm A');
                                                                    var end = moment(timesheet.events[i + 1].timeline_value, 'hh:mm A');

                                                                    var duration = moment.duration(end.diff(start));
                                                                    var mins = duration.asMinutes();

                                                                    if (mins < 0) {
                                                                        timlineMinutes = timlineMinutes + (24 * 60) + mins;


                                                                    } else {
                                                                        timlineMinutes = timlineMinutes + mins;

                                                                    }


                                                                }
                                                            } else {
                                                                if (timesheet.events[i].timeline_event == "I" && timesheet.events[i].timeline_event == "S") {


                                                                }


                                                            }
                                                        }

                                                        weekMinutes += timlineMinutes;

                                                        return (<li className="subBlock">
                                                            <div className="timesheetBlockInnerDiv">
                                                                <div className="mainCard position-relative">


                                                                    <div className="clearfix"></div>
                                                                    <div className="dmy">  {dat.format('dddd')}
                                                                    </div>
                                                                    <div className="btnDates">
                                                                        <div
                                                                            className="weekname day form-control">
                                                                            {dat.format('MM/DD/YYYY')}
                                                                        </div>
                                                                    </div>

                                                                    <div className="timesheetTimingBlock">
                                                                        <div className="times scrollbar-type">
                                                                            {(timesheet.events && timesheet.events.length > 0) ?

                                                                                timesheet.events.map((event, index) => {

                                                                                    var type = "";
                                                                                    var btnType = "";
                                                                                    var btn = "";
                                                                                    var dataType = "";
                                                                                    if (event.timeline_event == "I") {

                                                                                        type = "Shift Start";
                                                                                        btnType = "shiftstartTiming";
                                                                                        btn = "btnShiftStart";
                                                                                        dataType = "I";
                                                                                    } else if (event.timeline_event == "B") {
                                                                                        type = "Break Start";
                                                                                        btnType = "breakstartTiming";
                                                                                        btn = "btnBreakStart";
                                                                                        dataType = "B";
                                                                                    } else if (event.timeline_event == "S") {
                                                                                        type = "Break End";
                                                                                        btnType = "breakendTiming";
                                                                                        btn = "btnBreakEnd";
                                                                                        dataType = "S";
                                                                                    } else if (event.timeline_event == "O") {
                                                                                        type = "Shift End";
                                                                                        btnType = "shiftendTiming";
                                                                                        btn = "btnShiftEnd";
                                                                                        dataType = "O";
                                                                                    }


                                                                                    return (<div
                                                                                        className="scheduleTimingDiv">
                                                                                        <div
                                                                                            className="scheduleTimingDiv_l w-48 float-left">
                                                                                            <label>  {type} :</label>
                                                                                        </div>
                                                                                        <div
                                                                                            className="scheduleTimingDiv_r w-52 float-right position-relative">

                                                                                            <div className={btn}
                                                                                                 data-type={dataType}>
                                                                                                <div
                                                                                                    data-type={dataType}
                                                                                                    className={"open-timepicker form-control  " + btnType}>{event.timeline_value}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>);
                                                                                }) : ''}
                                                                            {(timesheet.holidayEvents && timesheet.holidayEvents.length > 0) ?
                                                                            <div className="holidaysText">---- Holiday Hours ----</div>:''}

                                                                            {(timesheet.holidayEvents && timesheet.holidayEvents.length > 0) ?

                                                                                timesheet.holidayEvents.map((holidayEvent, index) => {

                                                                                    var type = "";
                                                                                    var btnType = "";
                                                                                    var btn = "";
                                                                                    var dataType = "";
                                                                                    if (holidayEvent.timeline_event == "I") {

                                                                                        type = "Shift Start";
                                                                                        btnType = "shiftstartTiming";
                                                                                        btn = "btnShiftStart";
                                                                                        dataType = "I";
                                                                                    } else if (holidayEvent.timeline_event == "B") {
                                                                                        type = "Break Start";
                                                                                        btnType = "breakstartTiming";
                                                                                        btn = "btnBreakStart";
                                                                                        dataType = "B";
                                                                                    } else if (holidayEvent.timeline_event == "S") {
                                                                                        type = "Break End";
                                                                                        btnType = "breakendTiming";
                                                                                        btn = "btnBreakEnd";
                                                                                        dataType = "S";
                                                                                    } else if (holidayEvent.timeline_event == "O") {
                                                                                        type = "Shift End";
                                                                                        btnType = "shiftendTiming";
                                                                                        btn = "btnShiftEnd";
                                                                                        dataType = "O";
                                                                                    }


                                                                                    return (<div
                                                                                        className="scheduleTimingDiv">
                                                                                        <div
                                                                                            className="scheduleTimingDiv_l w-48 float-left">
                                                                                            <label>  {type} :</label>
                                                                                        </div>
                                                                                        <div
                                                                                            className="scheduleTimingDiv_r w-52 float-right position-relative">

                                                                                            <div className={btn}
                                                                                                 data-type={dataType}>
                                                                                                <div
                                                                                                    data-type={dataType}
                                                                                                    className={"open-timepicker form-control  " + btnType}>{holidayEvent.timeline_value}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>);
                                                                                }) : ''}
                                                                        </div>
                                                                        {/*<div className="total-hrs-timing1">
                                                                            {(timlineMinutes / 60).toFixed(2)} hrs
                                                                        </div>*/}

                                                                        <div className="totalContainer">
                                                                            <div className="total-hrs-timing mt-1">
                                                                                <div className="vertically-center">
                                                                                    <div
                                                                                        className="regularHoursContainer hoursContainer">
                                                                                        <div
                                                                                            className="float-left">Regular
                                                                                            Hours :
                                                                                        </div>
                                                                                        <div
                                                                                            className="regularHours float-right">{timesheet.regularHours.toFixed(2)}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="overtimeHoursContainer hoursContainer">
                                                                                        <div
                                                                                            className="float-left">Overtime
                                                                                            Hours :
                                                                                        </div>
                                                                                        <div
                                                                                            className="overtimeHours float-right">{timesheet.overtimeHours.toFixed(2)}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="ptoHoursContainer hoursContainer">
                                                                                        <div className="float-left">PTO
                                                                                            Hours :
                                                                                        </div>
                                                                                        <div
                                                                                            className="ptoHours float-right">{timesheet.ptoHours.toFixed(2)}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="vacationHoursContainer hoursContainer">
                                                                                        <div
                                                                                            className="float-left">Vacation
                                                                                            Hours :
                                                                                        </div>
                                                                                        <div
                                                                                            className="vacationHours float-right">{timesheet.vacationHours.toFixed(2)}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="vacationHoursContainer hoursContainer">
                                                                                        <div
                                                                                            className="float-left">Holiday
                                                                                            Hours :
                                                                                        </div>
                                                                                        <div
                                                                                            className="vacationHours float-right">{timesheet.holidayHours.toFixed(2)}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="totalHoursContainer">
                                                                                        <div
                                                                                            className="float-left">Total
                                                                                            Hours :
                                                                                        </div>
                                                                                        <div
                                                                                            className="totalHours float-right">{timesheet.totalHours.toFixed(2)}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </li>);
                                                    })


                                                    : <h2 class="w-100 text-center">No Timesheet Data Available</h2>}
                                            </ul>
                                            <div class="clearfix"></div>

                                            <div
                                                class="total-hrs-right w-100 my-3 float-left total-hrs-bottom">
                                                <p><label>Total Hours</label>{week.weeklyTotal.toFixed(2)}</p>
                                            </div>
                                        </div>);
                                    }) : ""}

                                {(this.state.schedules.schedule && this.state.schedules.schedule.length > 0) ?
                                    <div
                                        className="clock-outBlock clock-outBlockFirst scheduleBlock mt-2 float-left w-100">

                                        {/*<div className="float-left w-100 schedule-print-title">
                                            <h2>Schedule - {localStorage.getItem("emp_name")+" "+localStorage.getItem("emp_lastname")}</h2>
                                            <h4>Total Hours: {hours.toFixed(2)}</h4>
                                        </div>*/}

                                        <div className="float-left w-100 date-title my-2">
                                            <p className="text-center ml-5 d-inline-block  start-end my-2">

                                                My Schedules

                                            </p>

                                            <div className="float-right  mt-2 ">

                                                <a href={"/timereport/schedule/download/" + this.state.payperiodIndex}
                                                   target="_blank"
                                                   className="mt-1 no-hover"><span
                                                    className="material-icons">
download
</span> </a>
                                                <a href={"/timereport/schedule/print/" + this.state.payperiodIndex}
                                                   target="_blank" className="ml-2 no-hover"><span
                                                    className="material-icons btnPrint">
print
</span> </a>


                                            </div>
                                        </div>
                                        <ul id="scheduleBlockMain" className="mainBlock">
                                            {this.state.schedules.schedule.map((item, index) => {


                                                var shiftStartTime = moment(item.schedule_hour_from, 'HH:mm:ss');
                                                var shiftEndTime = moment(item.schedule_hour_to, 'HH:mm:ss');


                                                var span = "";
                                                span = moment.duration(shiftEndTime.diff(shiftStartTime));


                                                var brektype = item.schedule_break_type;
                                                var brek = item.schedule_break;

                                                var minutes = span.asMinutes();


                                                if (minutes < 0) {
                                                    hours = (24 * 60) + minutes;


                                                } else {
                                                    hours = minutes;

                                                }


                                                hours = (hours - brek) / 60;


                                                if (brektype == "M") {
                                                    brektype = " Mins";

                                                } else {
                                                    brektype = " Hours";

                                                    brek = brek / 60;

                                                }

                                                scheduleTotal += hours;
                                                return (<li className="subBlock">
                                                    <div className="scheduleBlockInnerDiv">
                                                        <div className="mainCard position-relative">

                                                            <div className="clearfix"></div>
                                                            <div className="btnDays">
                                                                <div
                                                                    className="weekname day form-control">
                                                                    {this.state.days[item.schedule_hour_day]}
                                                                </div>
                                                            </div>
                                                            <div className="scheduleTimingBlock">
                                                                <div className="scheduleTimingDiv">
                                                                    <div
                                                                        className="scheduleTimingDiv_l w-48 float-left">
                                                                        <label>Start Shift :</label>
                                                                    </div>
                                                                    <div
                                                                        className="scheduleTimingDiv_r w-52 float-right position-relative">

                                                                        <div className="btnShiftStart">
                                                                            <div
                                                                                className="open-timepicker form-control shiftstartTiming"
                                                                            >{shiftStartTime.format(" hh:mm A")}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="scheduleTimingDiv">
                                                                    <div
                                                                        className="scheduleTimingDiv_l w-48 float-left">
                                                                        <label>Break :</label>
                                                                    </div>
                                                                    <div
                                                                        className="scheduleTimingDiv_r w-52 float-right">
                                                                        <div className="btnShiftBreak">
                                                                            <div
                                                                                className="form-control breakTiming">{brek + " " + brektype}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="scheduleTimingDiv">
                                                                    <div
                                                                        className="scheduleTimingDiv_l w-48 float-left">
                                                                        <label>Shift End :</label>
                                                                    </div>
                                                                    <div
                                                                        className="scheduleTimingDiv_r w-52 float-right">
                                                                        <div className="btnShiftEnd">
                                                                            <div
                                                                                className="form-control shiftendTiming">{shiftEndTime.format(" hh:mm A")}</div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="total-hrs-timing"> {hours.toFixed(2) + " hrs"}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </li>);
                                            })}

                                        </ul>
                                        <div
                                            className="total-hrs-right w-100 my-3 float-left total-hrs-bottom">

                                            <p><label>Total Hours</label>{scheduleTotal.toFixed(2)}</p>

                                        </div>
                                    </div> : ''}

                            </div>
                        </div>
                    </div>
                </div>
            </main>


            <Modal closeOnEsc={false} closeOnOverlayClick={false} showCloseIcon={false} open={open}
                   onClose={this.onCloseModal} center>
                <h2>&nbsp;&nbsp;&nbsp;&nbsp;{this.state.loading}...&nbsp;&nbsp;&nbsp;&nbsp;</h2>

                <p style={{textAlign: 'center'}}>
                    <img width={'40'} height={'40'} alt="Loading..." src={'/img/loading1.gif'}/>
                </p>
            </Modal>
        </div>);
    }


}

export default withRouter(timereport);
