import React, {Component, useEffect, useState} from 'react';
import {Link, Redirect, withRouter} from 'react-router-dom'
import {API_ROOT, DOMAIN, IMAGE_ROOT} from "../api-config";
import 'react-responsive-modal/styles.css';
import Modal from "react-responsive-modal";
import axios from "axios";
import Swal from "sweetalert2";
import moment from 'moment-timezone';
import 'react-calendar/dist/Calendar.css';
import $ from "jquery";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";


import "react-datepicker/dist/react-datepicker.css";

import {Helmet} from "react-helmet";


let list = [];


const useCheckMobileScreen = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (width <= 768);
}


class printreport extends Component {

    constructor(props, context) {
        super(props, context);
        console.log(DOMAIN);
        this.idleTimer = null
        this.handleOnAction = this.handleOnAction.bind(this);
        this.handleOnActive = this.handleOnActive.bind(this);
        this.handleOnIdle = this.handleOnIdle.bind(this);

        this.state = {
            redirect404: false,
            redirect: false,
            swalButton: "",
            open: false,
            show: false,
            type: this.props.match.params.type,
            action: this.props.match.params.action,
            payperiods: [],
            payperiodIndex: this.props.match.params.id,
            loading: 'Loading',
            schedules: [],
            days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]


        };


    };

    componentDidUpdate(prevProps, prevState) {

    }


    componentWillMount() {

        if (!localStorage.getItem("emp_id")) {

            this.setState({redirect: true});
        }
        this.setState({
            swalButton: Swal.mixin({
                customClass: {
                    confirmButton: 'dialogButton',
                    cancelButton: 'dialogButton'
                },
                buttonsStyling: false,
            })
        });

    }


    componentDidMount() {

        $('body').css('background','#fff');
        $("body").removeClass("login-page");


        this.fetchPayPeriods();


    }


    toProperCase = (txt) => {

        if (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        } else {
            return "";
        }
    }
    loadProperties = () => {

    }

    onOpenModal = (msg) => {

        this.setState({loading: msg});
        this.setState({open: true});

    };
    onCloseModal = () => {
        this.setState({open: false});
    };

    logout = e => {
        localStorage.clear();
        this.setState({redirect: true});
    }


    fetchPayPeriods = () => {


        this.onOpenModal("Loading");

        axios.post(API_ROOT + '/payperiods', {
            emp_id: localStorage.getItem("emp_id"),
            emp_token: localStorage.getItem("emp_token"),
            merchant_id: localStorage.getItem("merchant_id"),

        }).then(response => {

            this.onCloseModal();

            if (response.data.status == 1) {


                this.setState({payperiods: response.data.payperiods});


                if (response.data.data[0].schedule_id != "") {

                    for (var i = 0; i < response.data.schedules.length; i++) {

                        if (response.data.schedules[i].schedule_id == response.data.data[0].schedule_id) {
                            this.setState({schedules: response.data.schedules[i]});

                            break;
                        }
                    }
                }
                var index = parseInt(this.state.payperiodIndex);

                this.setState({payperiodIndex: index});


                setTimeout(this.loader, 1000);


            } else {
                this.state.swalButton.fire({
                    title: response.data.message,
                    allowOutsideClick: false
                });

            }
        }).catch(error => {
            this.onCloseModal();
            console.log(error);
        });


    }

    loader = () => {
        if (this.state.action == "print") {

           // this.print();
        } else if (this.state.action == "download") {
            this.downloadPDF();
        }
    }
    print = () => {
        window.onafterprint = window.close;
        window.print();
    }
    downloadPDF = () => {


        this.onOpenModal("Generating PDF");

        var name = localStorage.getItem("emp_name");



        var thisi = this;
        html2canvas(document.body).then(function (canvas) {
            const imgData = canvas.toDataURL('img/png', .50);

            console.log(imgData);

            const pdf = new jsPDF('p', 'mm', 'a4');

            let imgh = canvas.height;
            let imgw = canvas.width;

            let res = imgw / imgh;

            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();


        /*    let imgHeight = pageHeight - 50;
            let imgWidth = imgHeight * res;


*/
            let imgWidth =210-20;
            let imgHeight = (210-20)/res;

            pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight, undefined,'FAST');

            // pdf.output('xssdataurlnewwindow');


            if (thisi.state.type == "schedule") {
                name = "Schedule - " + name;
            } else {
                name = "Time Report - " + name;
            }
            console.log(name);
            pdf.save(name + ".pdf", {returnPromise: true}).then(function () {

               setTimeout( window.close,2000);


            });


        });

    }

    save = () => {

    }

    render() {

        const {open} = this.state;
        var hours = 0;
        var totalPayperiodMimutes = 0;

        var scheduleTotal = 0;
        if (this.state.redirect404) {
            return <Redirect to="/404"/>;
        }
        if (this.state.redirect) {
            return <Redirect to="/login"/>;
        }

        return (<div id="mainBG" className="bg-login  w-100">
            <Helmet>
                <title>{"Time Clock - " + this.toProperCase(localStorage.getItem("merchant_subdomain"))}</title>

            </Helmet>


            <main className="w-100" id="report">
                <div className=" mt-0 pt-0 time-clock w-100">
                    <div className="time-clock-contentInner bg-white float-left w-100 schedule-print-title">


                        {(this.state.type == "timesheet" && this.state.payperiods[this.state.payperiodIndex + 1] && this.state.payperiods[this.state.payperiodIndex + 1].weeks && this.state.payperiods[this.state.payperiodIndex + 1].weeks.length > 0) ?
                            <div className="float-left w-100 ">

                                {this.state.payperiods[this.state.payperiodIndex + 1].weeks.map((week, index) => {

                                    var weekMinutes = 0;


                                    if (week.timesheet.length > 0) {

                                        week.timesheet.map((timesheet, index) => {


                                            var dat = moment(timesheet.date, "YYYY-MM-DD");


                                            var timlineMinutes = 0;
                                            for (var i = 0; i < timesheet.events.length; i++) {

                                                if (timesheet.events[i + 1]) {
                                                    if (timesheet.events[i].timeline_event == "I" && timesheet.events[i + 1].timeline_event == "B" || timesheet.events[i].timeline_event == "S" && timesheet.events[i + 1].timeline_event == "O" || timesheet.events[i].timeline_event && timesheet.events[i + 1].timeline_event == "O") {


                                                        var start = moment(timesheet.events[i].timeline_value, 'hh:mm A');
                                                        var end = moment(timesheet.events[i + 1].timeline_value, 'hh:mm A');

                                                        var duration = moment.duration(end.diff(start));
                                                        var mins = duration.asMinutes();

                                                        if (mins < 0) {
                                                            timlineMinutes = timlineMinutes + (24 * 60) + mins;


                                                        } else {
                                                            timlineMinutes = timlineMinutes + mins;

                                                        }


                                                    }
                                                } else {
                                                    if (timesheet.events[i].timeline_event == "I" && timesheet.events[i].timeline_event == "S") {


                                                        /*var start = moment(timesheet.events[i].timeline_value, 'hh:mm A');

                                                        var end = moment();

                                                        var duration = moment.duration(end.diff(start));
                                                        var mins = duration.asMinutes();

                                                        if (mins <= 0) {
                                                            minutes = minutes + (24 * 60) + mins;


                                                        } else {
                                                            minutes = minutes + mins;

                                                        }*/
                                                    }


                                                }
                                            }
                                            weekMinutes += timlineMinutes;

                                        })


                                    }
                                    totalPayperiodMimutes += weekMinutes;
                                })}


                                <h2>Time Report - {localStorage.getItem("emp_name")}</h2>
                                <h4 className="mt-3">{this.state.payperiods[this.state.payperiodIndex + 1].payperiod_from1 + " - " + this.state.payperiods[this.state.payperiodIndex + 1].payperiod_to1}</h4>
                                <h4 className="mt-3">Total Hours: {(this.state.payperiods[this.state.payperiodIndex + 1].payPeriodTotal).toFixed(2)}</h4>

                            </div> : ''}

                        {(this.state.type == "timesheet" && this.state.payperiods[this.state.payperiodIndex + 1] && this.state.payperiods[this.state.payperiodIndex + 1].weeks && this.state.payperiods[this.state.payperiodIndex + 1].weeks.length > 0) ?


                            this.state.payperiods[this.state.payperiodIndex + 1].weeks.map((week, index) => {

                                var weekMinutes = 0;


                                if (week.timesheet.length > 0) {

                                    week.timesheet.map((timesheet, index) => {


                                        var dat = moment(timesheet.date, "YYYY-MM-DD");


                                        var timlineMinutes = 0;
                                        for (var i = 0; i < timesheet.events.length; i++) {

                                            if (timesheet.events[i + 1]) {
                                                if (timesheet.events[i].timeline_event == "I" && timesheet.events[i + 1].timeline_event == "B" || timesheet.events[i].timeline_event == "S" && timesheet.events[i + 1].timeline_event == "O" || timesheet.events[i].timeline_event && timesheet.events[i + 1].timeline_event == "O") {


                                                    var start = moment(timesheet.events[i].timeline_value, 'hh:mm A');
                                                    var end = moment(timesheet.events[i + 1].timeline_value, 'hh:mm A');

                                                    var duration = moment.duration(end.diff(start));
                                                    var mins = duration.asMinutes();

                                                    if (mins < 0) {
                                                        timlineMinutes = timlineMinutes + (24 * 60) + mins;


                                                    } else {
                                                        timlineMinutes = timlineMinutes + mins;

                                                    }


                                                }
                                            } else {
                                                if (timesheet.events[i].timeline_event == "I" && timesheet.events[i].timeline_event == "S") {


                                                    /*var start = moment(timesheet.events[i].timeline_value, 'hh:mm A');

                                                    var end = moment();

                                                    var duration = moment.duration(end.diff(start));
                                                    var mins = duration.asMinutes();

                                                    if (mins <= 0) {
                                                        minutes = minutes + (24 * 60) + mins;


                                                    } else {
                                                        minutes = minutes + mins;

                                                    }*/
                                                }


                                            }
                                        }
                                        weekMinutes += timlineMinutes;

                                    })


                                }

                                return (<div className="timesheetBlock bg-white border-top mt-2   mb-2">
                                    <a href="#" className="a_edit invisible d-none float-left disabled">Edit</a>
                                    <div class="parent">
                                        <div className="child"><h4 className="float-left">{moment(week.week_from,
                                            "YYYY-MM-DD").format("MMMM DD, YYYY") + " - " + moment(week.week_to, "YYYY-MM-DD").subtract(1, 'days').format("MMMM DD, YYYY")}</h4>
                                        </div>
                                        <div className="child">
                                            {(this.state.payperiods[this.state.payperiodIndex + 1].payperiod_status=="A")?  <p className="text-center total-hrs-timing1 border-0 m-0 p-1  d-inline  editStatus  approvedBtnBlack start-end">
                                            APPROVED
                                            </p>:<p className="text-center total-hrs-timing1 border-0 m-0 p-1  d-inline  editStatus  approvedBtnBlack start-end">UNAPPROVED</p>}</div>
                                        <div className="child"><h4 className="float-right">Total
                                            Hours: {week.weeklyTotal.toFixed(2)}</h4></div>
                                    </div>

                                    <div className="clearfix"></div>

                                    <div className="timesheetBlockMain p-0 row mt-4">

                                        {(week.timesheet.length > 0) ?

                                            week.timesheet.map((timesheet, index) => {


                                                var dat = moment(timesheet.date, "YYYY-MM-DD");


                                                var timlineMinutes = 0;
                                                for (var i = 0; i < timesheet.events.length; i++) {

                                                    if (timesheet.events[i + 1]) {
                                                        if (timesheet.events[i].timeline_event == "I" && timesheet.events[i + 1].timeline_event == "B" || timesheet.events[i].timeline_event == "S" && timesheet.events[i + 1].timeline_event == "B"  || timesheet.events[i].timeline_event == "S" && timesheet.events[i + 1].timeline_event == "O" || timesheet.events[i].timeline_event && timesheet.events[i + 1].timeline_event == "O") {


                                                            var start = moment(timesheet.events[i].timeline_value, 'hh:mm A');
                                                            var end = moment(timesheet.events[i + 1].timeline_value, 'hh:mm A');

                                                            var duration = moment.duration(end.diff(start));
                                                            var mins = duration.asMinutes();

                                                            if (mins < 0) {
                                                                timlineMinutes = timlineMinutes + (24 * 60) + mins;


                                                            } else {
                                                                timlineMinutes = timlineMinutes + mins;

                                                            }


                                                        }
                                                    } else {
                                                        if (timesheet.events[i].timeline_event == "I" && timesheet.events[i].timeline_event == "S") {


                                                            /*var start = moment(timesheet.events[i].timeline_value, 'hh:mm A');

                                                            var end = moment();

                                                            var duration = moment.duration(end.diff(start));
                                                            var mins = duration.asMinutes();

                                                            if (mins <= 0) {
                                                                minutes = minutes + (24 * 60) + mins;


                                                            } else {
                                                                minutes = minutes + mins;

                                                            }*/
                                                        }


                                                    }
                                                }


                                                return (<div className="scheduleBlockDiv ml-2  position-relative">
                                                    <div className="timesheetBlockInnerDiv">
                                                        <div className="mainCard position-relative">


                                                            <div className="clearfix"></div>
                                                            <div className="dmy">  {dat.format('dddd')}
                                                            </div>
                                                            <a href="#" className="btnDates">
                                                                <button type="button"
                                                                        className="weekname day form-control">
                                                                    {dat.format('MM/DD/YYYY')}
                                                                </button>
                                                            </a>

                                                            <div className="timesheetTimingBlock">
                                                                <div className="times  overflow-visible scrollbar-type">
                                                                    {(timesheet.events && timesheet.events.length > 0) ?

                                                                        timesheet.events.map((event, index) => {

                                                                            var type = "";
                                                                            var btnType = "";
                                                                            var btn = "";
                                                                            var dataType = "";
                                                                            if (event.timeline_event == "I") {

                                                                                type = "Shift Start";
                                                                                btnType = "shiftstartTiming";
                                                                                btn = "btnShiftStart";
                                                                                dataType = "I";
                                                                            } else if (event.timeline_event == "B") {
                                                                                type = "Break Start";
                                                                                btnType = "breakstartTiming";
                                                                                btn = "btnBreakStart";
                                                                                dataType = "B";
                                                                            } else if (event.timeline_event == "S") {
                                                                                type = "Break End";
                                                                                btnType = "breakendTiming";
                                                                                btn = "btnBreakEnd";
                                                                                dataType = "S";
                                                                            } else if (event.timeline_event == "O") {
                                                                                type = "Shift End";
                                                                                btnType = "shiftendTiming";
                                                                                btn = "btnShiftEnd";
                                                                                dataType = "O";
                                                                            }


                                                                            return (<div
                                                                                className="scheduleTimingDiv">
                                                                                <div
                                                                                    className="scheduleTimingDiv_l w-48 float-left">
                                                                                    <label>  {type} :</label>
                                                                                </div>
                                                                                <div
                                                                                    className="scheduleTimingDiv_r w-52 float-right position-relative">

                                                                                    <a className={btn}
                                                                                       data-type={dataType}
                                                                                       href="#"> <input
                                                                                        data-type={dataType}
                                                                                        type="button"
                                                                                        className={"open-timepicker form-control  " + btnType}
                                                                                        value={event.timeline_value}/>
                                                                                    </a>
                                                                                </div>
                                                                            </div>);
                                                                        }) : ''}


                                                                    {(timesheet.holidayEvents && timesheet.holidayEvents.length > 0) ?
                                                                        <div className="holidaysText">---- Holiday Hours ----</div>:''}

                                                                    {(timesheet.holidayEvents && timesheet.holidayEvents.length > 0) ?

                                                                        timesheet.holidayEvents.map((holidayEvent, index) => {

                                                                            var type = "";
                                                                            var btnType = "";
                                                                            var btn = "";
                                                                            var dataType = "";
                                                                            if (holidayEvent.timeline_event == "I") {

                                                                                type = "Shift Start";
                                                                                btnType = "shiftstartTiming";
                                                                                btn = "btnShiftStart";
                                                                                dataType = "I";
                                                                            } else if (holidayEvent.timeline_event == "B") {
                                                                                type = "Break Start";
                                                                                btnType = "breakstartTiming";
                                                                                btn = "btnBreakStart";
                                                                                dataType = "B";
                                                                            } else if (holidayEvent.timeline_event == "S") {
                                                                                type = "Break End";
                                                                                btnType = "breakendTiming";
                                                                                btn = "btnBreakEnd";
                                                                                dataType = "S";
                                                                            } else if (holidayEvent.timeline_event == "O") {
                                                                                type = "Shift End";
                                                                                btnType = "shiftendTiming";
                                                                                btn = "btnShiftEnd";
                                                                                dataType = "O";
                                                                            }


                                                                            return (<div
                                                                                className="scheduleTimingDiv">
                                                                                <div
                                                                                    className="scheduleTimingDiv_l w-48 float-left">
                                                                                    <label>  {type} :</label>
                                                                                </div>
                                                                                <div
                                                                                    className="scheduleTimingDiv_r w-52 float-right position-relative">

                                                                                    <div className={btn}
                                                                                         data-type={dataType}>
                                                                                        <div
                                                                                            data-type={dataType}
                                                                                            className={"open-timepicker form-control  " + btnType}>{holidayEvent.timeline_value}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>);
                                                                        }) : ''}

                                                                </div>

                                                               {/* <div className="total-hrs-timing">
                                                                    {(timlineMinutes / 60).toFixed(2)} hrs
                                                                </div>*/}
                                                                <div className="totalContainer">
                                                                    <div className="total-hrs-timing mt-1">
                                                                        <div className="vertically-center">
                                                                            <div
                                                                                className="regularHoursContainer hoursContainer">
                                                                                <div
                                                                                    className="float-left">Regular
                                                                                    Hours :
                                                                                </div>
                                                                                <div
                                                                                    className="regularHours float-right">{timesheet.regularHours.toFixed(2)}
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="overtimeHoursContainer hoursContainer">
                                                                                <div
                                                                                    className="float-left">Overtime
                                                                                    Hours :
                                                                                </div>
                                                                                <div
                                                                                    className="overtimeHours float-right">{timesheet.overtimeHours.toFixed(2)}
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="ptoHoursContainer hoursContainer">
                                                                                <div className="float-left">PTO
                                                                                    Hours :
                                                                                </div>
                                                                                <div
                                                                                    className="ptoHours float-right">{timesheet.ptoHours.toFixed(2)}
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="vacationHoursContainer hoursContainer">
                                                                                <div
                                                                                    className="float-left">Vacation
                                                                                    Hours :
                                                                                </div>
                                                                                <div
                                                                                    className="vacationHours float-right">{timesheet.vacationHours.toFixed(2)}
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="vacationHoursContainer hoursContainer">
                                                                                <div
                                                                                    className="float-left">Holiday
                                                                                    Hours :
                                                                                </div>
                                                                                <div
                                                                                    className="vacationHours float-right">{timesheet.holidayHours.toFixed(2)}
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="totalHoursContainer">
                                                                                <div
                                                                                    className="float-left">Total
                                                                                    Hours :
                                                                                </div>
                                                                                <div
                                                                                    className="totalHours float-right">{timesheet.totalHours.toFixed(2)}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>);
                                            })


                                            : <h2 className="w-100 text-center">No Timesheet Data Available</h2>}
                                    </div>
                                    <div className="clearfix"></div>


                                </div>);
                            }) : ""}


                        {(this.state.type == "schedule" && this.state.schedules.schedule && this.state.schedules.schedule.length > 0) ?
                            <div className="float-left w-100 schedule-print-title">

                                {this.state.schedules.schedule.map((item, index) => {


                                    var shiftStartTime = moment(item.schedule_hour_from, 'HH:mm:ss');
                                    var shiftEndTime = moment(item.schedule_hour_to, 'HH:mm:ss');


                                    var span = "";
                                    span = moment.duration(shiftEndTime.diff(shiftStartTime));


                                    var brek = item.schedule_break;

                                    var minutes = span.asMinutes();


                                    if (minutes < 0) {
                                        hours = (24 * 60) + minutes;


                                    } else {
                                        hours = minutes;

                                    }


                                    hours = (hours - brek) / 60;


                                    scheduleTotal += hours;

                                })}

                                <h2>Schedule
                                    - {localStorage.getItem("emp_name")}</h2>
                                <h4 class="mt-3">Total Hours: {scheduleTotal.toFixed(2)}</h4>

                                <br/>
                            </div> : ''}
                        {(this.state.type == "schedule" && this.state.schedules.schedule && this.state.schedules.schedule.length > 0) ?
                            <div
                                className="clock-outBlock border-top pl-0 pr-0 clock-outBlockFirst scheduleBlock mt-2 bg-white  float-left w-100">


                                <div className="float-left w-100 date-title schedule-print-title">
                                    <p className="d-inline-block float-left start-end">

                                        <h4>My Schedules</h4>

                                    </p>

                                    <div className="float-right  mt-2 ">

                                        <h4>Total Hours: {scheduleTotal.toFixed(2)}</h4>
                                    </div>
                                </div>
                                <div id="scheduleBlockMain" className="scheduleBlockMain p-0 mt-5">
                                    {this.state.schedules.schedule.map((item, index) => {


                                        var shiftStartTime = moment(item.schedule_hour_from, 'HH:mm:ss');
                                        var shiftEndTime = moment(item.schedule_hour_to, 'HH:mm:ss');


                                        var span = "";
                                        span = moment.duration(shiftEndTime.diff(shiftStartTime));


                                        var brektype = item.schedule_break_type;
                                        var brek = item.schedule_break;

                                        var minutes = span.asMinutes();


                                        if (minutes < 0) {
                                            hours = (24 * 60) + minutes;


                                        } else {
                                            hours = minutes;

                                        }


                                        hours = (hours - brek) / 60;


                                        if (brektype == "M") {
                                            brektype = " Mins";

                                        } else {
                                            brektype = " Hours";

                                            brek = brek / 60;

                                        }


                                        return (<div className="scheduleBlockDiv position-relative">
                                            <div className="scheduleBlockInnerDiv">
                                                <div className="mainCard position-relative">

                                                    <div className="clearfix"></div>
                                                    <a href="#" className="btnDays">
                                                        <button type="button"
                                                                className="weekname day form-control">
                                                            {this.state.days[item.schedule_hour_day]}
                                                        </button>
                                                    </a>
                                                    <div className="scheduleTimingBlock">
                                                        <div className="scheduleTimingDiv">
                                                            <div
                                                                className="scheduleTimingDiv_l w-48 float-left">
                                                                <label>Start Shift :</label>
                                                            </div>
                                                            <div
                                                                className="scheduleTimingDiv_r w-52 float-right position-relative">

                                                                <a className="btnShiftStart" href="#">
                                                                    <input type="button"
                                                                           className="open-timepicker form-control shiftstartTiming"
                                                                           value={shiftStartTime.format(" hh:mm A")}/>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="scheduleTimingDiv">
                                                            <div
                                                                className="scheduleTimingDiv_l w-48 float-left">
                                                                <label>Break :</label>
                                                            </div>
                                                            <div
                                                                className="scheduleTimingDiv_r w-52 float-right">
                                                                <a className="btnShiftBreak" href="#">
                                                                    <input type="button"
                                                                           className="form-control breakTiming"
                                                                           value={brek + " " + brektype}/>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="scheduleTimingDiv">
                                                            <div
                                                                className="scheduleTimingDiv_l w-48 float-left">
                                                                <label>Shift End :</label>
                                                            </div>
                                                            <div
                                                                className="scheduleTimingDiv_r w-52 float-right">
                                                                <a className="btnShiftEnd" href="#">
                                                                    <input type="button"
                                                                           className="form-control shiftendTiming"
                                                                           value={shiftEndTime.format(" hh:mm A")}/>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="total-hrs-timing"> {hours.toFixed(2) + " hrs"}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>);
                                    })}

                                </div>

                            </div> : ''}

                    </div>
                </div>
            </main>


            <Modal closeOnEsc={false} closeOnOverlayClick={false} showCloseIcon={false} open={open}
                   onClose={this.onCloseModal} center>
                <h2>&nbsp;&nbsp;&nbsp;&nbsp;{this.state.loading}...&nbsp;&nbsp;&nbsp;&nbsp;</h2>

                <p style={{textAlign: 'center'}}>
                    <img width={'40'} height={'40'} alt="Loading..." src={'/img/loading1.gif'}/>
                </p>
            </Modal>
        </div>);
    }

    handleOnAction(event) {
        console.log('user did something', event)
    }

    handleOnActive(event) {
        console.log('user is active', event)
        console.log('time remaining', this.idleTimer.getRemainingTime())
    }

    handleOnIdle(event) {
        console.log('user is idle', event)
        console.log('last active', this.idleTimer.getLastActiveTime())


        this.logout();

    }
}

export default withRouter(printreport);
